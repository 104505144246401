<template>
	<div>
		<div class="pa-6 elevation-6 rounded-lg bg-white mb-8 position-relative">
			<div class="d-flex flex-wrap mx-n3">
				<v-col class="pt-0 pb-2 v-col-12 v-col-md-3">
					<v-autocomplete
							label="Select operators"
							variant="outlined"
							item-title="full_name"
							item-value="id"
							:items="operatorsList"
							v-model="operatorSelected"
							return-object
							multiple
							v-model:search.sync="searchOperators"
							@update:modelValue="searchOperators = ''"
					>
						<template v-slot:selection="{ index }">
							<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ operatorSelected.length }}</span>
						</template>
						<template #item="{ props, item }">
							<v-list-subheader v-if="props.header">
								{{ props.header }}
							</v-list-subheader>
							<v-divider v-else-if="props.divider" />
							<v-list-item v-else v-bind="props" :title="item.title">
								<template  v-slot:prepend="{ isActive }">
									<v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
								</template>
							</v-list-item>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col class="pt-0 pb-2 v-col-12 v-col-md-3">
					<v-select
							label="Select shifts"
							variant="outlined"
							item-title="name"
							item-value="id"
							:items="shiftNumbers"
							v-model="shiftSelected"
							multiple
							return-object
					>
						<template #selection="{ index }">
							<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ shiftSelected.length }}</span>
						</template>
						<template #item="{ props, item }">
							<v-list-item v-bind="props" :title="item.title">
								<template  v-slot:prepend="{ isActive }">
									<v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
								</template>
							</v-list-item>
						</template>
					</v-select>
				</v-col>
				<v-col class="pt-0 pb-2 v-col-12 v-col-md-3">
					<v-autocomplete
							label="Select models"
							variant="outlined"
							:items="modelsList"
							v-model="modelSelected"
							item-title="nickname"
							item-value="id"
							multiple
							return-object
							v-model:search.sync="searchModels"
							@update:modelValue="searchModels = ''"
					>
						<template #selection="{ index }">
							<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ modelSelected.length }}</span>
						</template>
						<template #item="{ props, item }">
							<v-list-subheader v-if="props.header">{{ props.header }}</v-list-subheader>
							<v-divider v-else-if="props.divider" />
							<v-list-item v-else v-bind="props" :title="item.title">
								<template  v-slot:prepend="{ isActive }">
									<v-checkbox-btn :model-value="isActive"></v-checkbox-btn>
								</template>
							</v-list-item>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col class="pt-0 pb-7 v-col-12 v-col-md-3">
					<VueDatePicker
							class="clearable-false"
							range
							v-model="date"
							auto-apply
							:format="format"
							:clearable="false"
					/>
				</v-col>
			</div>
			<v-row :justify="(userInfo.role === 'superuser' || userInfo.role === 'spectator') ? 'space-between' : 'end'">
				<v-col v-if="userInfo.role === 'superuser' || userInfo.role === 'spectator'" class="v-col-12 v-col-md-3">
					<v-autocomplete
							label="Select teamlead"
							variant="outlined"
							item-title="full_name"
							item-value="id"
							v-model="leadSelected"
							:items="teamLeads"
							density="comfortable"
							multiple
							return-object
							v-model:search.sync="searchLeads"
							@update:modelValue="searchLeads = ''"
					>
						<template #selection="{ index }">
							<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ leadSelected.length }}</span>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col cols="auto">
					<v-btn :size="xs ? 'default' : 'large'" class="mr-4" variant="outlined" @click="clearChips">Clear filter</v-btn>
					<v-btn :size="xs ? 'default' : 'large'" color="primary" @click="getItems">Search</v-btn>
				</v-col>
			</v-row>
			<div v-if="loading" class="overlay d-flex justify-center align-center">
				<v-progress-circular color="primary" indeterminate></v-progress-circular>
			</div>
		</div>
		<div class="ma-n1">
			<v-chip
					class="ma-1"
					v-for="item in allChips"
					:key="item.id"
					:color="setChipsColor(item.type)"
					closable
					@click:close="deleteFilter(item)"
			>
				{{item.title}}
			</v-chip>
		</div>
	</div>
</template>

<script>
  import { useDisplay } from 'vuetify'
	import {mapState} from "vuex";

  export default {
    setup () {
      // Destructure only the keys we want to use
      const { xs } = useDisplay();

      return { xs }
    },


    name: 'ShiftFilter',


		props: {
			loading: {}
		},


    data() {
      return {
				date: this.setDefaultDateRange(),
        format: 'dd.MM.yyyy',
        allChips: [],
				leadSelected: [],
				operatorSelected: [],
				shiftSelected: [],
				modelSelected: [],
				operatorsList: [],
				shiftNumbers: [],
        modelsList: [],
				searchOperators: '',
				searchModels: '',
				searchLeads: '',
      };
    },


		computed: {
			...mapState({
				userInfo: state => state.user.info,
				userLoading: state => state.user.userLoading,
				userLoaded: state => state.user.userLoaded,
				myGroup: state => state.user.myGroup,
				myGroupLoading: state => state.user.myGroupLoading,
				myGroupLoaded: state => state.user.myGroupLoaded,
				teamLeads: state => state.user.teamLeads,
			}),
		},


    watch: {
      operatorSelected: {
				handler() {
					this.sumAllChips()
				},
				deep: true
      },
      shiftSelected: {
				handler() {
					this.sumAllChips()
				},
				deep: true
      },
      modelSelected: {
				handler() {
					this.sumAllChips()
				},
				deep: true
      },
			leadSelected: {
				handler() {
					this.sumAllChips()
				},
				deep: true
			},
			userLoaded() {
				if (this.myGroupLoaded && this.userLoaded) {
					this.getOperators();
					this.getShiftNumbers();
					this.getModels();
				}
			},
			myGroupLoaded() {
				if (this.myGroupLoaded && this.userLoaded) {
					this.getOperators();
					this.getShiftNumbers();
					this.getModels();
				}
			},
    },


		created() {
			if (this.myGroupLoaded && this.userLoaded) {
				this.getOperators();
				this.getShiftNumbers();
				this.getModels();
			}

			let data = {
				date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
				date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
			}
			this.$emit('loadHistory', data)
		},


		methods: {

			setDefaultDateRange() {
				const now = new Date();
				const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
				const end = now;
				return [start, end]
			},

      sumAllChips() {
				let operators = [];
				this.operatorSelected.forEach((item) => {
					operators.push({type: 'operator', title: item.full_name, id: item.id})
				})
				let shifts = [];
				this.shiftSelected.forEach((item) => {
					shifts.push({type: 'shift', title: item.name, id: item.id})
				})
				let models = [];
				this.modelSelected.forEach((item) => {
					models.push({type: 'model', title: item.nickname, id: item.id})
				})
				let leads = [];
				this.leadSelected.forEach((item) => {
					leads.push({type: 'lead', title: item.full_name, id: item.id})
				})
        this.allChips = [...operators, ...shifts, ...models, ...leads]
      },

			setChipsColor(type) {
				if (type === 'operator') return 'primary'
				if (type === 'shift') return 'secondary'
				if (type === 'model') return 'green-lighten-1'
				if (type === 'lead') return 'purple-lighten-1'
			},

			formatingDate(date, format) {
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = (date.getDate().toString().padStart(2, '0'));
				if (format === 'dd.MM.yyyy') {
					return `${day}.${month}.${year}`;
				} else if (format === 'yyyy-MM-dd') {
					return `${year}-${month}-${day}`;
				}
			},

      deleteFilter(item) {
        if (item.type === 'operator') {
          const index = this.operatorSelected.findIndex(el => el.id === item.id);
          this.operatorSelected.splice(index, 1)
        }
				if (item.type === 'shift') {
					const index = this.shiftSelected.findIndex(el => el.id === item.id);
					this.shiftSelected.splice(index, 1)
				}
        if (item.type === 'model') {
          const index = this.modelSelected.findIndex(el => el.id === item.id);
          this.modelSelected.splice(index, 1)
        }
				if (item.type === 'lead') {
					const index = this.leadSelected.findIndex(el => el.id === item.id);
					this.leadSelected.splice(index, 1)
				}
				this.getItems()
      },

			clearChips() {
				this.operatorSelected = [];
				this.shiftSelected = [];
				this.modelSelected = [];
				this.leadSelected = [];
				this.date = this.setDefaultDateRange();
				this.getItems()
			},

			getModels() {
				this.modelsList = this.$store.getters['user/getModels'];
			},

			getShiftNumbers() {
				this.shiftNumbers = this.$store.getters['user/getShiftNumbers']
			},

			getOperators() {
				this.operatorsList = this.$store.getters['user/getOperators']
			},

			getItems() {
				let params = {
					operators: String(this.operatorSelected.map(el => el.id)),
					shift_numbers: String(this.shiftSelected.map(el => el.id)),
					only_fans_models: String(this.modelSelected.map(el => el.id)),
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				}
				if (this.userInfo.role === 'superuser') {
					params.team_leads = String(this.leadSelected.map(el => el.id))
				}
				this.$emit('loadHistory', params)
			}
    }
  }
</script>

<style lang="less">
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.6);
}

.counter-combobox .v-combobox__selection {
	display: none;
}

.counter-select .v-select__selection {
	display: none;
}
</style>