<script>
import apiManagers from "@/api/managers"
import FeedList from "@/components/feed/FeedList.vue";
import FeedFilter from "@/components/feed/FeedFilter.vue";
import FeedAdd from "@/components/feed/FeedAdd.vue";
import {mapState} from "vuex";

export default {
	name: "FeedContainer",


	components: {
		FeedAdd,
		FeedFilter,
		FeedList
	},


	props: {
		modelInfo: {
			default: null
		}
	},


	data() {
		return {
			databookItems: [],
			databookItemsLoading: false,
			databookItemsLoaded: false,
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
			nextPage: null,
			showButton: false,
			moreLoading: false,
			moreLoaded: false,
			filterDate: null,
			filterModel: null,
			filterManager: null,
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			userLoaded: state => state.user.userLoaded,
		})
	},


	created() {
		this.getFeed();
		this.getDatabook();
	},


	watch: {
		filterDate() {
			this.getFeed();
		},
		filterModel() {
			this.getFeed();
		},
		filterManager() {
			this.getFeed();
		},
	},


	methods: {
		async getDatabook() {
			this.databookItemsLoading = true;
			this.databookItemsLoaded = false;
			try {
				let params = {}
				this.databookItems = await apiManagers.getDatabook(params);
				this.databookItemsLoading = false;
				this.databookItemsLoaded = true;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getFeed() {
			this.items = [];
			this.nextPage = null;
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				const data = {
					page: 1,
					models_infos: this.filterModel,
					users: this.filterManager,
					created_at_after: this.formatingDate(this.filterDate[0], 'yyyy-MM-dd'),
					created_at_before: this.formatingDate(this.filterDate[1], 'yyyy-MM-dd'),
				}
				const response = await apiManagers.getLiveFeed(data);
				this.items = response.results;
				this.nextPage = response.next;
				this.nextPage ? this.showButton = true : this.showButton = false;
				this.itemsLoading = false;
				this.itemsLoaded = true;
			} catch(error) {
				console.log(error)
			}
		},

		add(response) {
			this.items.unshift(response)
		},

		del(id) {
			const index = this.items.findIndex(item => item.id === id);
			if (index !== -1) {
				this.items.splice(index, 1)
			}
		},

		async showMore() {
			this.moreLoading = true;
			this.moreLoaded = false;
			const data = {
				page: this.nextPage,
				created_at_after: this.formatingDate(this.filterDate[0], 'yyyy-MM-dd'),
				created_at_before: this.formatingDate(this.filterDate[1], 'yyyy-MM-dd'),
				users: this.filterManager,
				models_infos: this.filterModel
			}
			const response = await apiManagers.getLiveFeed(data);
			this.items = this.items.concat(response.results);
			this.nextPage = response.next;
			this.nextPage ? this.showButton = true : this.showButton = false;
			this.moreLoading = false;
			this.moreLoaded = true;
		},

		setFilterDate(value) { this.filterDate = value },
		setFilterModel(value) { this.filterModel = value },
		setFilterManager(value) { this.filterManager = value },
		setFilter(value) {
			this.filterDate = value.date;
			this.filterModel = value.model;
			this.filterManager = value.manager
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
			const end = now;
			return [start, end]
		},
	}
}
</script>

<template>
	<v-row>
		<v-col cols="9">
			<div class="mb-3">
				<FeedAdd
						:model-info="modelInfo"
						:items="databookItems"
						:itemsLoading="databookItemsLoading"
						:itemsLoaded="databookItemsLoaded"
						@add="add"
				/>
			</div>
			<template v-if="itemsLoaded">
				<template v-if="items.length > 0">
					<FeedList
							:items="items"
							:itemsLoading="itemsLoading"
							:itemsLoaded="itemsLoaded"
							@delete="del"
					/>
				</template>
				<template v-else>
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>There is no data matching the selected filters</span>
					</div>
				</template>
			</template>
			<template v-else>
				<v-skeleton-loader
						v-for="(item, index) in 3"
						:key="index"
						class="rounded-lg elevation-2 mb-6"
						type="table-heading, list-item-two-line"
				></v-skeleton-loader>
			</template>
			<div
					v-if="showButton" class="d-flex justify-center mt-8"
			>
				<v-btn
						color="primary"
						size="large"
						@click="showMore"
						:disabled="moreLoading"
						:loading="moreLoading"
				>Show more</v-btn>
			</div>
		</v-col>
		<v-col cols="3">
			<FeedFilter
					class="position-sticky"
					style="top: 80px; z-index: 1"
					:model-info="modelInfo"
					:role="userInfo.role"
					:items="databookItems"
					:itemsLoading="databookItemsLoading"
					:itemsLoaded="databookItemsLoaded"
					@date="setFilterDate"
					@model="setFilterModel"
					@manager="setFilterManager"
					@filter="setFilter"
			/>
		</v-col>
	</v-row>
</template>

<style scoped>

</style>