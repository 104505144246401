<script>
// import CustomRequestCard from "@/components/CustomRequestCard.vue";

import CustomRequestDetailDialog from "@/components/CustomRequestDetailDialog.vue";

export default {
	name: "CustomRequestsPage",


	components: {
		CustomRequestDetailDialog
		// CustomRequestCard
	},


	data() {
		return {
			detailDialogOpened: false,
			format: 'dd.MM.yyyy',
			pageTitle: 'Custom request',
			tab: '',
			search: '',
			date: this.setDefaultDateRange(),
			payoutEditOpen: false,
			pageLoaded: true,
			items: [{id: 1}],
			itemsLoading: false,
			editedItem: {},
			defaultItem: {},
			itemsCount: 1,
			totalSum: 0,
		}
	},


	watch: {
		date() {
			// this.paginationReset();
			// this.getPayouts();
			// localStorage.requests_date_start = this.date[0];
			// localStorage.requests_date_end = this.date[1];
		},
	},


	methods: {
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), 1);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		handleCreateClick() {
			this.payoutEditOpen = true;
			this.editedItem = Object.assign({}, this.defaultItem)
		},
		openDetailDialog() {
			this.detailDialogOpened = true
		}
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">

		<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

		<div class="mb-6">
			<v-btn
					color="primary"
					prepend-icon="mdi-plus"
					@click="handleCreateClick"
					text="new payout"
			></v-btn>
		</div>

		<v-tabs
				class="mb-6"
				center-active
				color="primary"
				v-model="tab"
		>
			<v-tab value="">All</v-tab>
			<v-tab value="progress">Progress</v-tab>
			<v-tab value="done">Done</v-tab>
		</v-tabs>

		<v-row justify="space-between" align="end" class="mb-6">
			<v-col cols="auto">
				<div class="text-subtitle-2 mb-2">Creation date:</div>
				<VueDatePicker
						class="density-compact clearable-false"
						v-model="date"
						range
						auto-apply
						:format="format"
						:clearable="false"
				/>
			</v-col>
			<v-col cols="auto">
				<div style="max-width: 100%; width: 400px">
					<v-text-field
							v-model="search"
							variant="underlined"
							label="Search"
							append-inner-icon="mdi-magnify"
							density="compact"
							hide-details
					></v-text-field>
				</div>
			</v-col>
		</v-row>

		<template v-if="pageLoaded">
			<v-row no-gutters align="center">
				<v-col>
					<span class="text-subtitle-2 font-weight-bold">Number of payouts: {{ itemsCount }}</span>
					<span class="text-subtitle-2 font-weight-bold ml-4">Total sum: {{ totalSum }}</span>
				</v-col>
			</v-row>
			<div class="mt-4">
				<div class="mt-2" v-if="itemsCount > 0">
					<div class="mb-6" v-for="(item, index) in items" :key="index">
<!--						<CustomRequestCard-->
<!--								:info="item"-->
<!--						/>-->
						<v-card class="pa-6" @click="openDetailDialog">1</v-card>
					</div>
				</div>
				<div v-else class="mt-10 text-body-1 text-center">
					<span>...</span><br>
					<span>Нет данных, соответствующих выбранным фильтрам</span>
				</div>
			</div>
		</template>
		<template v-if="itemsLoading">
			<v-row no-gutters align="center" justify="space-between">
				<v-col class="d-flex">
					<v-skeleton-loader height="" width="170" class="bg-transparent" type="text"></v-skeleton-loader>
					<v-skeleton-loader width="140" class="bg-transparent" type="text"></v-skeleton-loader>
				</v-col>
				<v-col cols="auto">
					<v-skeleton-loader width="170" class="bg-transparent" type="text"></v-skeleton-loader>
				</v-col>
			</v-row>
			<div class="mt-3">
				<v-skeleton-loader
						v-for="(item, index) in 3"
						:key="index"
						class="rounded-lg elevation-2 mb-6"
						type="table-heading, list-item-two-line, actions"
				></v-skeleton-loader>
			</div>
		</template>

		<v-dialog v-model="detailDialogOpened" width="1200">
			<CustomRequestDetailDialog />
		</v-dialog>
	</div>
</template>

<style scoped>

</style>