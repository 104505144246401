<script>
import {mapState} from "vuex";

export default {
	name: "FeedFilter",


	props: {
		modelInfo: {
			default: null
		},
		items: {},
		itemsLoading: {},
		itemsLoaded: {},
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			date: this.setDefaultDateRange(),
			selectedModel: null,
			selectedManager: null,
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			userLoaded: state => state.user.userLoaded,
			managers: state => state.managers.managers,
			managersLoaded: state => state.managers.managersLoaded,
		})
	},


	created() {
		if (this.modelInfo) {
			this.selectedModel = this.modelInfo
		}
		if (this.userInfo.role === 'client_manager') {
			this.selectedManager = this.userInfo.id
		}
	},


	mounted() {
		// this.$emit('date', this.date);
		// this.$emit('model', this.selectedModel);
		// this.$emit('manager', this.selectedManager);
		this.$emit('filter', {date: this.date, model: this.selectedModel, manager: this.selectedManager})
	},


	watch: {
		date() {
			this.$emit('date', this.date)
		},
		selectedModel() {
			this.$emit('model', this.selectedModel)
		},
		selectedManager() {
			this.$emit('manager', this.selectedManager)
		},
	},


	methods: {
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
			const end = now;
			return [start, end]
		},
	}
}
</script>

<template>
	<v-sheet class="pa-4" rounded="lg" elevation="0">
		<div class="text-subtitle-1 font-weight-bold mb-3">Filters</div>
		<v-row>
			<v-col cols="12">
				<VueDatePicker
						class="clearable-false"
						v-model="date"
						auto-apply
						:format="format"
						range
						:clearable="false"
				/>
			</v-col>
			<v-col cols="12" v-if="modelInfo === null">
				<v-autocomplete
						:items="items"
						:loading="!itemsLoaded"
						:disabled="!itemsLoaded"
						v-model="selectedModel"
						item-title="only_fans_model.nickname"
						item-value="id"
						label="Model"
						variant="outlined"
						hide-details
				></v-autocomplete>
			</v-col>
			<v-col cols="12" v-if="userInfo.role === 'hocm'">
				<v-autocomplete
						:items="managers"
						:loading="!managersLoaded"
						:disabled="!managersLoaded"
						v-model="selectedManager"
						label="Client manager"
						item-title="full_name"
						item-value="id"
						variant="outlined"
						hide-details
						clearable
				>
				</v-autocomplete>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<style scoped>

</style>