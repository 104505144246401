<script>
import {mapState} from "vuex";
import apiTeams from '@/api/teams'
import ScheduleItem from "@/components/ScheduleItem.vue";

export default {
	name: "MyTeamsPage",
	components: {ScheduleItem},


	data() {
		return {
			pageTitle: 'My teams',
			ungroupedModelsList: [],
			searchItem: [],
			tabs: 'detail',
			selectedModel: '',
			leadSelected: [],
			search: '',
			schedule: null,
			scheduleLoading: false,
			scheduleLoaded: false,
			shiftIndexes: null,
			shiftIndexesLoading: false,
			shiftIndexesLoaded: false,
			scheduleBoard: [],
			editMode: false,
			operatorsList: [],
			shiftNumbers: [],
			searchLeads: '',
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			userLoading: state => state.user.userLoading,
			userLoaded: state => state.user.userLoaded,
			myGroup: state => state.user.myGroup,
			myGroupLoading: state => state.user.myGroupLoading,
			myGroupLoaded: state => state.user.myGroupLoaded,
			teamLeads: state => state.user.teamLeads,
		}),
		filteredItems() {
			return this.searchItem.filter((item) => {
				if (this.leadSelected.length > 0) {
					if (item.nickname.toLowerCase().match(this.search) && this.leadSelected.some((element) => element === item.teamlead.id)) return true
				} else {
					if (item.nickname.toLowerCase().match(this.search)) return true
				}
			});
		},
	},


	created() {
		if (this.myGroupLoaded && this.userLoaded) {
			this.getUngroupedModels();
			this.getOperators();
			this.getShiftNumbers();
		}
		this.getShiftIndexes()
	},


	watch: {
		myGroupLoaded() {
			if (this.myGroupLoaded && this.userLoaded) {
				this.getUngroupedModels();
				this.getOperators();
				this.getShiftNumbers();
			}
		},
		userLoaded() {
			if (this.myGroupLoaded && this.userLoaded) {
				this.getOperators();
				this.getShiftNumbers();
			}
		},
		search(value) {
			if (value === null) this.search = ''
		},
		selectedModel(value) {
			this.getSchedule(value.id)
		},
		scheduleLoaded(value) {
			if (value) {
				this.createScheduleBoard()
			}
		},
		shiftIndexesLoaded(value) {
			if (value) {
				this.createScheduleBoard()
			}
		},
	},


	methods: {

		getUngroupedModels() {
			const response = this.$store.getters['user/getUngroupedModels'];
			this.ungroupedModelsList = response;
			this.searchItem = this.ungroupedModelsList;
			this.selectedModel = this.ungroupedModelsList[0]
		},

		getModelObjectById(id) {
			return this.$store.getters['user/getModelObjectById'](id);
		},

		async getShiftIndexes() {
			try {
				this.shiftIndexesLoading = true;
				this.shiftIndexesLoaded = false;
				this.shiftIndexes = await apiTeams.getShiftIndexes();
				this.shiftIndexesLoading = false;
				this.shiftIndexesLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},

		async getSchedule(id) {
			this.scheduleBoard = [];
			this.scheduleLoading = true;
			this.scheduleLoaded = false;
			const response = await apiTeams.getScheduleByModel(id);
			this.schedule = response.sort((item1, item2) => item1.shift_index.number > item2.shift_index.number ? 1 : -1);
			this.schedule = response;
			this.scheduleLoading = false;
			this.scheduleLoaded = true;
		},

		createScheduleBoard() {
			if (this.scheduleLoaded && this.shiftIndexesLoaded) {
				this.shiftIndexes.forEach((index) => {
					const target = this.schedule.find(item => item.shift_index.id === index.id);
					if (target) {
						this.scheduleBoard.push(target)
					} else {
						const newItem = {
							shift_index: index,
							only_fans_model: this.selectedModel
						}
						this.scheduleBoard.push(newItem)
					}
				})
			}
		},

		changeModel(item) {
			this.selectedModel = item;
			this.editMode = false
		},

		getOperators() {
			if (this.userInfo.role === 'superuser' || this.userInfo.role === 'senior_operator') {
				this.operatorsList = this.$store.getters['user/getOperators']
			}
			if (this.userInfo.role === 'senior_operator') {
				this.operatorsList = this.$store.getters['user/getOwnOperators']
			}
			if (this.userInfo.role === 'team_lead') {
				this.operatorsList = this.$store.getters['user/getOwnOperators']
			}
		},

		getShiftNumbers() {
			this.shiftNumbers = this.$store.getters['user/getShiftNumbers']
		},

		patchBoard(response) {
			let targetIndex = this.scheduleBoard.findIndex((item) => item.shift_index.id === response.shift_index.id);
			this.scheduleBoard[targetIndex] = response;
		}

	}
}
</script>

<template>
	<div class="page-container pt-4 pb-4 fill-height d-flex flex-column">

		<div class="flex-0-0">
			<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>
		</div>

		<div class="flex-1-1 overflow-y-hidden">
			<v-sheet color="white" class="rounded-lg fill-height">
				<v-row no-gutters class="fill-height flex-nowrap">


					<v-col cols="3" class="fill-height flex-1-1">
						<div class="fill-height d-flex flex-column">
							<div class="pa-4 pb-6 flex-0-0">
								<div class="text-h5 mb-4">Model groups</div>
								<v-text-field
										class="mb-4"
										variant="outlined"
										density="compact"
										label="Search"
										v-model="search"
										clearable
										hide-details
								></v-text-field>
								<v-autocomplete
										v-if="userInfo.role === 'superuser'"
										class="mb-4"
										variant="outlined"
										density="compact"
										label="Teamleads"
										item-title="full_name"
										item-value="id"
										v-model="leadSelected"
										:items="teamLeads"
										multiple
										clearable
										hide-details
										v-model:search.sync="searchLeads"
										@update:modelValue="searchLeads = ''"
								></v-autocomplete>
								<div class="text-subtitle-2">{{ filteredItems.length }} out of {{ ungroupedModelsList.length }} models shown</div>
							</div>
							<div class="overflow-y-auto flex-1-1">
								<div class="fill-height" v-if="myGroupLoaded && userLoaded">
									<template v-for="item in filteredItems" :key="item.id">
										<v-hover>
											<template v-slot:default="{ isHovering, props }">
												<div
														v-bind="props"
														class="cursor-pointer d-flex transition"
														:class="(item.id === selectedModel.id || isHovering) ? 'bg-blue-lighten-5' : ''"
														@click="changeModel(item)"
												>
													<div
															class="transition"
															:class="item.id === selectedModel.id ? 'bg-primary' : ''"
															style="width: 4px"
													></div>
													<div class="pa-4"> {{ item.nickname }}</div>
												</div>
											</template>
										</v-hover>
										<v-divider></v-divider>
									</template>
								</div>
							</div>
						</div>
					</v-col>


					<v-divider vertical></v-divider>


					<v-col cols="9" class="py-4 px-6">
						<template v-if="scheduleLoaded">
							<div class="text-h5 mb-2">{{ selectedModel.nickname }}</div>
							<div class="mb-4">
								<v-chip variant="elevated" color="deep-purple-lighten-2">{{ selectedModel.teamlead.full_name }}</v-chip>
							</div>
							<v-tabs
									class="mb-8"
									v-model="tabs"
									color="primary"
									align-tabs="center"
									fixed-tabs
							>
								<v-tab value="detail">Shift details</v-tab>
								<!--							<v-tab value="history">History</v-tab>-->
							</v-tabs>
							<ScheduleItem
									v-for="(item, index) in scheduleBoard"
									:key="index"
									:info="item"
									:edit="editMode"
									:operators-list="operatorsList"
									:shift-numbers-list="shiftNumbers"
									@patch="patchBoard"
							/>
							<v-divider></v-divider>
							<v-row no-gutters justify="end" class="pt-6">
								<v-btn v-if="editMode" color="primary" variant="outlined" @click="editMode = false">Quit edit mode</v-btn>
								<v-btn v-else color="primary" @click="editMode = true">Edit mode</v-btn>
							</v-row>
						</template>
						<template v-else>
							<v-skeleton-loader max-width="400" type="heading"></v-skeleton-loader>
							<v-skeleton-loader max-width="180" type="heading"></v-skeleton-loader>
							<v-skeleton-loader type="table-row@2" class="mb-4"></v-skeleton-loader>
							<v-skeleton-loader max-width="180" type="heading"></v-skeleton-loader>
							<v-skeleton-loader type="table-row@2" class="mb-4"></v-skeleton-loader>
							<v-skeleton-loader max-width="180" type="heading"></v-skeleton-loader>
							<v-skeleton-loader type="table-row@2" class="mb-4"></v-skeleton-loader>
							<v-skeleton-loader type="divider, actions" class="bg-transparent mb-6"></v-skeleton-loader>
						</template>
					</v-col>


				</v-row>
			</v-sheet>
		</div>
	</div>
</template>

<style scoped>

</style>