<script>
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: "PaymentEditDialog",


	props: {
		info: {}
	},


	data() {
		return {
			formLoading: false,
			format: 'dd.MM.yyyy',
			fields: {},
			errors: {},
			expenseItems: [],
			expenseItemsLoading: false,
			expenseItemsLoaded: false,
			tools: [],
			toolsLoading: false,
			toolsLoaded: false,
			currencies: [],
			currenciesLoading: false,
			currenciesLoaded: false,
			paymentSystems: [],
			paymentSystemsLoading: false,
			paymentSystemsLoaded: false,
			departments: [],
			departmentsLoading: false,
			departmentsLoaded: false,
			transactionsSummary: null,
			paymentsAmountError: '',
		}
	},


	created() {
		this.setFields();
		this.getExpenseItems();
		this.getPaymentTools();
		this.getCurrencies();
		this.getPaymentSystems();
		this.getDepartments();
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	watch: {
		transactionsSummary(value) {
			if (value > this.info.payment_amount) {
				this.paymentsAmountError = 'payments amount exceeded'
			} else {
				this.paymentsAmountError = ''
			}
		}
	},


	methods: {

		getSummary() {
			let summ = 0;
			this.fields.payment_transactions.forEach((item) => {
				summ = summ + Number(item.amount);
			})
			this.transactionsSummary = summ;
		},

		setFields() {
			this.fields.status = structuredClone(this.info.finance_review_result);
			if (this.info.date) {
				this.fields.date = new Date(this.info.date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'});
			} else {
				this.fields.date = null
			}
			if (this.info.head_review_date) {
				this.fields.head_review_date = new Date(this.info.head_review_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'});
			} else {
				this.fields.head_review_date = null
			}
			this.fields.created_by = structuredClone(this.info.created_by);
			this.fields.department = structuredClone(this.info.department);
			this.fields.head = structuredClone(this.info.head);
			this.fields.model_nickname = structuredClone(this.info.model_nickname);
			this.fields.payment_expense_item = structuredClone(this.info.payment_expense_item);

			this.fields.payment_amount = structuredClone(this.info.payment_amount);
			this.fields.payment_system = structuredClone(this.info.payment_system);
			this.fields.payment_currency = structuredClone(this.info.payment_currency);
			this.fields.payment_details = structuredClone(this.info.payment_details);
			this.fields.payment_comment = structuredClone(this.info.payment_comment);
			this.fields.payment_description = structuredClone(this.info.payment_description);

			this.fields.financier = structuredClone(this.userInfo.full_name);
			this.fields.payment_transactions = structuredClone(this.info.payment_transactions)

			if (this.fields.payment_transactions.length === 0) {
				this.fields.payment_transactions.push({ payment_tool: null, amount: this.info.payment_amount })
			}
		},

		addPaymentTool() {
			this.fields.payment_transactions.forEach((item) => {
				item.amount = 0
			})
			this.fields.payment_transactions.push({ payment_tool: null, amount: 0 })
			this.paymentsAmountError = '';
			this.transactionsSummary = null;
		},

		async getExpenseItems() {
			this.expenseItemsLoading = true;
			this.expenseItemsLoaded = false;
			this.expenseItems = await apiFinance.getExpenseItems();
			this.expenseItemsLoading = false;
			this.expenseItemsLoaded = true;
		},

		async getPaymentTools() {
			this.toolsLoading = true;
			this.toolsLoaded = false;
			this.tools = await apiFinance.getPaymentTools()
			this.toolsLoading = false;
			this.toolsLoaded = true;
		},

		async getCurrencies() {
			this.currenciesLoading = true;
			this.currenciesLoaded = false;
			this.currencies = await apiFinance.getCurrencies()
			this.currenciesLoading = false;
			this.currenciesLoaded = true;
		},

		async getPaymentSystems() {
			this.paymentSystemsLoading = true;
			this.paymentSystemsLoaded = false;
			this.paymentSystems = await apiFinance.getPaymentSystems()
			this.paymentSystemsLoading = false;
			this.paymentSystemsLoaded = true;
		},

		async getDepartments() {
			this.departmentsLoading = true;
			this.departmentsLoaded = false;
			this.departments = await apiFinance.getDepartments()
			this.departmentsLoading = false;
			this.departmentsLoaded = true;
		},

		async handlePaymentUpdate() {
			this.formLoading = true;
			try {
				const tools = [];
				this.fields.payment_transactions.forEach((item) => {
					if (item.payment_tool && item.amount > 0) {
						tools.push({amount: item.amount, payment_tool: item.payment_tool})
					}
				})
				const data = {
					finance_review_result: this.fields.status,
					payment_transactions: tools,
					payment_amount: this.fields.payment_amount,
					payment_system: this.fields.payment_system,
					payment_currency: this.fields.payment_currency,
					payment_details: this.fields.payment_details,
					payment_comment: this.fields.payment_comment,
					payment_description: this.fields.payment_description,
					model_nickname: this.fields.model_nickname,
					department: this.fields.department,
				};
				if (this.fields.payment_expense_item) {
					data.payment_expense_item = this.fields.payment_expense_item
				}
				const response = await apiFinance.patchPaymentRequest(this.info.id, data);
				this.$emit('update', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		}
	}
}
</script>

<template>
	<v-card class="pa-6" :disabled="formLoading">
		<v-form class="fill-height d-flex flex-column" @submit.prevent>

			<div class="flex-0-0">
				<div class="mb-4 text-h5">Payment id: {{ info.id }}</div>
				<v-divider></v-divider>
			</div>

			<div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">
				<v-stepper editable class="elevation-0" v-model="fields.status">
					<v-stepper-header>
						<v-stepper-item
								:disabled="info.finance_review_result !== 'review'"
								value="review"
								color="white"
								title="To work"
								edit-icon="mdi-pencil"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="reject"
								color="amber-lighten-4"
								title="Canceled"
								edit-icon="mdi-exclamation-thick"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="accept"
								color="green-lighten-4"
								title="Provided"
								edit-icon="mdi-check"
						></v-stepper-item>
					</v-stepper-header>
				</v-stepper>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Link info</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Request date</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									v-model="fields.date"
									prepend-inner-icon="mdi-calendar"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Request author</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									v-model="fields.created_by"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Department</div>
							<v-autocomplete
									:items="departments"
									:loading="!departmentsLoaded"
									:disabled="!departmentsLoaded"
									variant="outlined"
									placeholder="Department"
									v-model="fields.department"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Approved by</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									v-model="fields.head"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Approved date</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									v-model="fields.head_review_date"
									prepend-inner-icon="mdi-calendar"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2 text-grey">Model username</div>
							<v-autocomplete
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									v-model="fields.model_nickname"
									variant="outlined"
									placeholder="Model"
									item-title="nickname"
									item-value="nickname"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2 text-grey">Expense item</div>
							<v-combobox
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Expense item"
									:loading="!expenseItemsLoaded"
									:disabled="!expenseItemsLoaded"
									:items="expenseItems"
									v-model="fields.payment_expense_item"
									:return-object="false"
									:error-messages="errors.payment_expense_item"
							></v-combobox>
						</v-col>
					</v-row>
				</div>

				<v-divider></v-divider>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Payment info</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Amount</div>
							<v-text-field
									variant="outlined"
									placeholder="Amount"
									v-model="fields.payment_amount"
									:error-messages="errors.payment_amount"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Currency</div>
							<v-combobox
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Currency"
									:loading="!currenciesLoaded"
									:disabled="!currenciesLoaded"
									:items="currencies"
									v-model="fields.payment_currency"
									:return-object="false"
									:error-messages="errors.payment_currency"
							></v-combobox>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Payment system</div>
							<v-combobox
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Payment system"
									:loading="!paymentSystemsLoaded"
									:disabled="!paymentSystemsLoaded"
									:items="paymentSystems"
									v-model="fields.payment_system"
									:return-object="false"
									:error-messages="errors.payment_system"
							></v-combobox>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Payment details</div>
							<v-text-field
									variant="outlined"
									placeholder="Amount"
									v-model="fields.payment_details"
									:error-messages="errors.payment_details"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Payment note</div>
							<v-text-field
									variant="outlined"
									placeholder="Amount"
									v-model="fields.payment_comment"
									:error-messages="errors.payment_comment"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Payment description</div>
							<v-textarea
									variant="outlined"
									v-model="fields.payment_description"
									placeholder="Description"
									:error-messages="errors.payment_description"
							></v-textarea>
						</v-col>
					</v-row>
				</div>

				<v-divider></v-divider>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Provide detail</div>
					<v-row class="flex-wrap" style="max-width: 700px">
						<v-col cols="12">
							<div class="text-body-1 mb-2">Financier</div>
							<v-text-field disabled readonly placeholder="Financier" v-model="fields.financier" variant="outlined"></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Payment info</div>
							<v-row v-for="(item, index) in fields.payment_transactions" :key="index">
								<v-col cols="8">
									<v-combobox
											variant="outlined"
											item-title="name"
											item-value="name"
											placeholder="Payment tool"
											:loading="!toolsLoaded"
											:disabled="!toolsLoaded"
											:items="tools"
											v-model="item.payment_tool"
											:return-object="false"
									></v-combobox>
								</v-col>
								<v-col cols="4">
									<v-text-field
											type="number"
											placeholder="Financier"
											v-model="item.amount"
											variant="outlined"
											@update:model-value="getSummary"
											:error-messages="paymentsAmountError"
									></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<div class="mb-4" v-if="fields.payment_transactions.length < 3">
						<label>
							<v-btn icon="mdi-plus" size="small" color="primary" @click="addPaymentTool"></v-btn>
							<span class="text-body-2 ml-2">Add payment info</span>
						</label>
					</div>
				</div>

			</div>

			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="pt-4">
					<v-btn class="ml-2" color="primary" @click="handlePaymentUpdate" :disabled="formLoading || paymentsAmountError.length > 0" :loading="formLoading">Save</v-btn>
					<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
				</v-row>
			</div>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>