<template>
	<div class="fill-height d-flex flex-column">

		<div class="flex-1-1 d-flex flex-column position-relative">
			<div class="fill-height w-100 position-absolute" style="overflow-y: scroll">
				<div ref="wrapper" class="page-container pt-4" style="padding-bottom: 80px">
					<div class="page-wrap">
						<h1 class="text-h4 mb-8">План по моделям</h1>

						<v-row class="justify-space-between">
							<v-col cols="auto">
								<v-btn :disabled="!periodLoaded" prepend-icon="mdi-plus" variant="outlined" color="primary" @click="dialogEditModel = true">Добавить модель</v-btn>
							</v-col>
							<v-col cols="auto">
								<div style="max-width: 100%; width: 400px">
									<v-text-field
											:disabled="!periodLoaded"
											v-model="search"
											variant="underlined"
											label="Поиск по модели"
											append-inner-icon="mdi-magnify"
											density="compact"
									></v-text-field>
								</div>
							</v-col>
						</v-row>

						<div>

							<div class="d-flex" :class="{'w-fit': mdAndDown}">
								<div
										v-for="(item, index) in heads"
										:key="index"
										class="px-4 pt-2 pb-3 text-body-1 font-weight-medium"
										style="width: unset"
										:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
								>{{headName(item)}}</div>
							</div>

							<template v-if="periodsLoaded">

								<template v-if="periods.length > 0">
									<template v-if="periodLoaded">
										<template v-if="items.length > 0">
											<template v-if="filteredItems.length > 0">
												<template v-for="(item, index) in filteredItems" :key="index">
													<v-hover>
														<template v-slot:default="{ isHovering, props }">
															<div
																	v-bind="props"
																	class="d-flex elevation-1 rounded-lg mb-2 cursor-pointer transition"
																	:class="[{'w-fit': mdAndDown}, setItemBgColor(index, isHovering)]"
																	@click="openEditModel(item)"
															>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">{{ item.only_fans_model.nickname }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.plan_revenue + 'px' } : { width: sizes.plan_revenue + '%' }">{{ item.expected_revenue }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.plan_subsq + 'px' } : { width: sizes.plan_subsq + '%' }">{{ item.expected_subscribers }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.gg_of + 'px' } : { width: sizes.gg_of + '%' }">{{ item.expected_subscribers_gg}} / {{ item.expected_subscribers_oftv}}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.fact_revenue + 'px' } : { width: sizes.fact_revenue + '%' }">
																	<div class="d-flex align-center">
																		<span style="width: 70px" class="text-no-wrap">{{ item.fact_revenue }}</span>
																		<div style="width: 80px">
																			<v-progress-linear
																					:model-value="calcValue(item.expected_revenue, item.fact_revenue)"
																					:color="calcColor(item.expected_revenue, item.fact_revenue)"
																					rounded
																					height="12"
																			></v-progress-linear>
																		</div>
																		<span class="ml-2 text-no-wrap font-weight-bold" :class="'text-' + calcColor(item.expected_revenue, item.fact_revenue)">{{calcValue(item.expected_revenue, item.fact_revenue)}}%</span>
																	</div>
																</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.fact_subsq + 'px' } : { width: sizes.fact_subsq + '%' }">
																	<div class="d-flex align-center">
																		<span style="width: 70px" class="text-no-wrap">{{ item.fact_subscribers }}</span>
																		<div style="width: 80px">
																			<v-progress-linear
																					:model-value="calcValue(item.expected_subscribers, item.fact_subscribers)"
																					:color="calcColor(item.expected_subscribers, item.fact_subscribers)"
																					rounded
																					height="12"
																			></v-progress-linear>
																		</div>
																		<span class="ml-2 text-no-wrap font-weight-bold" :class="'text-' + calcColor(item.expected_subscribers, item.fact_subscribers)">{{calcValue(item.expected_subscribers, item.fact_subscribers)}}%</span>
																	</div>
																</div>
																<div class="flex-0-0 px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
																	<v-btn color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteModel(item)" ></v-btn>
																</div>
															</div>
														</template>
													</v-hover>
												</template>
											</template>
											<template v-else>
												<div class="mt-10 text-body-1 text-center">
													<span>...</span><br>
													<span>Моделей по заданному запросу не обнаружено</span>
												</div>
											</template>
										</template>
										<template v-else>
											<div class="mt-10 text-body-1 text-center">
												<span>...</span><br>
												<span>Добавьте модель, чтобы начать заполнять план</span>
											</div>
										</template>
									</template>
									<v-skeleton-loader v-else type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>
								</template>

								<template v-else>
									<div class="mt-10 text-body-1 text-center">
										<span>...</span><br>
										<span>Создайте первый план на месяц, чтобы начать работу</span>
									</div>
								</template>

							</template>

						</div>

						<v-dialog v-model="dialogDeleteModel" max-width="340px">
							<v-card class="pa-6">
								<div class="text-body-1 mb-2">Удалить модель из плана</div>
								<div class="text-subtitle-2">Вы уверены?</div>
								<v-row justify="end" no-gutters class="mt-10 pa-0">
									<v-btn color="primary" class="mr-2" variant="text" @click="closeDeleteModel">Отмена</v-btn>
									<v-btn color="primary" @click="deleteModelConfirm">Подтвердить</v-btn>
								</v-row>
							</v-card>
						</v-dialog>

						<v-dialog v-model="dialogEditModel" max-width="500px">
							<PlanModelEditDialog
									:edited="editedModelIndex"
									:info="editedModel"
									:period="period.id"
									:selected="selectedModels"
									@close="closeEditModel"
									@add="addModel"
									@edit="editModel"
							/>
						</v-dialog>

						<v-dialog v-model="dialogEditPlan" fullscreen persistent transition="slide-x-transition" max-width="1300px" class="right-position">
							<PlanEditDialog
									:edited="editedPlanIndex"
									:info="editedPlan"
									:periods="periods"
									@close="closeEditPlan"
									@add="addPlan"
									@edit="editPlan"
							/>
						</v-dialog>

					</div>
				</div>
			</div>


			<div class="page-container w-100 position-absolute" style="bottom: 0; z-index: 1" :style="`max-width: ${wrapperWidth}px`">
				<div class="d-flex mb-2 transition">
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Моделей:<br></span>{{ planTotalValues.modelsCount }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.plan_revenue + 'px' } : { width: sizes.plan_revenue + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма план revenue:<br></span> {{ planTotalValues.planRevenue }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.plan_subsq + 'px' } : { width: sizes.plan_subsq + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма план subsq:<br></span> {{ planTotalValues.planSubsq }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.gg_of + 'px' } : { width: sizes.gg_of + '%' }"></div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.fact_revenue + 'px' } : { width: sizes.fact_revenue + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма факт revenue:<br></span> {{ planTotalValues.factRevenue }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.fact_subsq + 'px' } : { width: sizes.fact_subsq + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Сумма факт subsq:<br></span> {{ planTotalValues.factSubsq }}</div>
					</div>
					<div class="flex-0-0" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }"></div>
				</div>
			</div>

		</div>

		<div class="flex-0-0">
			<div class="d-flex align-center bg-white">
				<v-skeleton-loader v-if="!periodsLoaded" type="avatar@2, chip@4" class="bg-transparent w-100"></v-skeleton-loader>
				<template v-else>
					<div class="d-flex pa-2 pr-6">
						<v-btn :disabled="!periods.length > 0" class="mr-2" icon="mdi-pencil" variant="text" color="primary" @click="openEditPlan"></v-btn>
						<v-btn icon="mdi-plus" variant="text" color="primary" @click="dialogEditPlan = true"></v-btn>
					</div>
					<template v-if="periods.length > 0">
						<v-tabs
								center-active
								color="primary"
								v-model="tab"
						>
							<v-tab v-for="(item, index) in periods" :key="index" :value="item.id">{{ item.name }}</v-tab>
						</v-tabs>
					</template>
					<template v-else>
						Список планов пуст. Добавьте новый план на месяц.
					</template>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
import apiPlan from '@/api/plan';
import PlanModelEditDialog from '@/components/PlanModelEditDialog.vue';
import PlanEditDialog from '@/components/PlanEditDialog.vue';
import {useDisplay} from "vuetify";

export default ({
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'ModelPlanPage',


	components: {
		PlanModelEditDialog,
		PlanEditDialog,
	},


	data() {
		return {
			heads: ['model', 'plan_revenue', 'plan_subsq', 'gg_of', 'fact_revenue', 'fact_subsq', 'action'],
			sizes: {
				model: 100/1000*150,
				plan_revenue: 100/1000*140,
				plan_subsq: 100/1000*140,
				gg_of: 100/1000*110,
				fact_revenue: 100/1000*195,
				fact_subsq: 100/1000*195,
				action: 100/1000*70,
			},
			sizesMobile: {
				model: '200',
				plan_revenue: '150',
				plan_subsq: '150',
				gg_of: '150',
				fact_revenue: '250',
				fact_subsq: '250',
				action: '100',
			},
			tableCellClass: 'px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
			dialogDeleteModel: false,
			dialogEditModel: false,
			dialogEditPlan: false,
			search: '',
			searchItem: [],
			tab: '',
			editedModelIndex: -1,
			editedModel: '',
			defaultModel: '',
			periods: [],
			periodsLoading: false,
			periodsLoaded: false,
			editedPlanIndex: -1,
			editedPlan: '',
			defaultPlan: '',
			period: null,
			periodLoaded: false,
			items: [],
			planTotalValues: {},
			wrapperWidth: 0,
		}
	},


	computed: {
		filteredItems() {
			return this.searchItem.filter((item) => {
				if (item.only_fans_model.nickname.toLowerCase().match(this.search)) return true
			});
		},
		selectedModels() {
			let result = [];
			this.items.forEach(item => {
				result.push(item.only_fans_model.nickname);
			})
			return result
		}
	},


	created() {
		this.getPeriods();
	},


	mounted() {
		this.wrapperWidth = this.$refs.wrapper.getBoundingClientRect().width
	},


	watch: {
		tab() {
			this.getPeriod();
		}
	},


	methods: {
		async getPeriods() {
			try {
				this.periodsLoaded = false;
				this.periods = await apiPlan.getPeriods();
				this.periodsLoaded = true
			} catch (error) {
				console.log('periods loading error: ', error)
			}
		},

		async getPeriod() {
			try {
				this.periodLoaded = false;
				this.period = await apiPlan.getPeriod(this.tab);
				const list = this.period.expected_results;
				list.forEach((item) => {
					if (item.only_fans_model === null) {
						item.only_fans_model = { nickname: '-' }
					}
				})
				this.items = list;
				this.searchItem = this.items;
				this.periodLoaded = true;
				this.calcTotal();
			} catch (error) {
				console.log('selected period loading error: ', error)
			}
		},

		headName(value) {
			if (value === 'model') return 'Модель';
			if (value === 'plan_revenue') return 'План revenue';
			if (value === 'plan_subsq') return 'План subsq';
			if (value === 'gg_of') return 'GG / OFTV';
			if (value === 'fact_revenue') return 'Факт revenue';
			if (value === 'fact_subsq') return 'Факт subsq';
		},

		setItemBgColor(index, hover) {
			if (hover) {
				return 'bg-cyan-lighten-5'
			} else if (index % 2) {
				return 'bg-grey-lighten-4'
			} else {
				return 'bg-white'
			}
		},

		calcValue(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			return Math.round(value2 / value1 * 100);
		},

		calcColor(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			const result = Math.round(value2 / value1 * 100);
			if (result > 60) {
				return 'cyan-darken-3'
			} else if (result <= 60 && result > 20) {
				return 'amber-darken-2'
			} else if (result <=20) {
				return 'deep-orange-darken-1'
			}
		},

		openEditModel(item) {
			this.editedModelIndex = this.items.indexOf(item);
			this.editedModel = Object.assign({}, item);
			this.dialogEditModel = true
		},

		closeEditModel() {
			this.dialogEditModel = false;
			this.$nextTick(() => {
				this.editedModel = Object.assign({}, this.deleteModel);
				this.editedModelIndex = -1
			})
		},

		deleteModel(item) {
			this.editedModelIndex = this.items.indexOf(item);
			this.editedModel = Object.assign({}, item);
			this.dialogDeleteModel = true
		},

		closeDeleteModel() {
			this.dialogDeleteModel = false;
			this.$nextTick(() => {
				this.editedModel = Object.assign({}, this.defaultModel);
				this.editedModelIndex = -1
			})
		},

		async deleteModelConfirm() {
			try {
				this.deleteLoading = true;
				await apiPlan.deletePlanModel(this.editedModel.id);
				this.closeDeleteModel();
				const index = this.items.findIndex(item => item.id === this.editedModel.id);
				if (index !== -1) {
					this.items.splice(index, 1)
				}
			} catch (error) {
				console.log('delete model error: ', error)
			} finally {
				this.deleteLoading = false;
			}
		},

		addModel(data) {
			this.items.push(data);
			this.calcTotal();
		},

		editModel(data) {
			const index = this.items.findIndex(item => item.id === data.id);
			this.items[index] = data;
			this.calcTotal();
		},

		openEditPlan() {
			this.editedPlanIndex = 1;
			this.editedPlan = Object.assign({}, this.period);
			this.dialogEditPlan = true
		},

		closeEditPlan() {
			this.dialogEditPlan = false;
			this.$nextTick(() => {
				this.editedPlan = Object.assign({}, this.defaultPlan);
				this.editedPlanIndex = -1
			})
		},

		addPlan(data) {
			const item = {
				end_date: data.end_date,
				id: data.id,
				name: data.name,
				start_date: data.start_date,
			}
			this.periods.push(item);
			this.periods.sort((item1, item2) => item1['start_date'] > item2['start_date'] ? 1 : -1).reverse();
		},

		editPlan(data) {
			const index = this.periods.findIndex(item => item.id === data.id);
			this.periods[index].name = data.name;
			this.periods[index].start_date = data.start_date;
			this.periods[index].end_date = data.end_date;
			this.period = data;
			this.items = data.expected_results;
			this.searchItem = this.items
		},

		calcTotal() {
			const modelsList = Array.from(new Set(this.filteredItems.map(item => item.only_fans_model.nickname )));
			let index = modelsList.indexOf('-');
			if (index !== -1) {
				modelsList.splice(index, 1);
			}
			const planRevenueList = this.filteredItems.map(item => item.expected_revenue);
			const planSubsqList = this.filteredItems.map(item => item.expected_subscribers);
			const factRevenueList = this.filteredItems.map(item => item.fact_revenue);
			const factSubsqList = this.filteredItems.map(item => item.fact_subscribers);
			console.log('modelsList: ', modelsList)
			this.planTotalValues.modelsCount = modelsList.length;
			this.planTotalValues.planRevenue = planRevenueList.reduce((acc, num) => acc + num, 0);
			this.planTotalValues.planSubsq = planSubsqList.reduce((acc, num) => acc + num, 0);
			this.planTotalValues.factRevenue = factRevenueList.reduce((acc, num) => acc + num, 0);
			this.planTotalValues.factSubsq = factSubsqList.reduce((acc, num) => acc + num, 0);
		},
	}
})
</script>

<style scoped>

</style>