<script>
import apiManagers from "@/api/managers";

export default {
	name: "FeedAdd",


	props: {
		modelInfo: {
			default: null
		},
		items: {},
		itemsLoading: {},
		itemsLoaded: {},
	},


	data: () => ({
		text: '',
		selectedModels: null,
		sendLoading: false,
		errors: {},
	}),


	created() {
		if (this.modelInfo) {
			this.selectedModels = this.modelInfo
		}
	},


	methods: {
		async post() {
			this.errors = {};
			try {
				this.sendLoading = true;
				const data = {
					models_infos: [this.selectedModels],
					text: this.text,
				}
				const response = await apiManagers.addFeed(data);
				this.$emit('add', response)
				this.text = '';
				this.selectedModels = null;
			} catch (error) {
				console.log(error);
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.sendLoading = false;
			}
		}
	}
}
</script>

<template>
	<v-card class="pa-4" elevation="0" rounded="lg" :disabled="sendLoading" :loading="sendLoading">
		<div class="text-body-1 font-weight-black mb-3">Add new post</div>
		<v-textarea
				v-model="text"
				variant="outlined"
				rows="5"
				placeholder="Some comment here"
				no-resize
				:error-messages="errors.text"
		></v-textarea>
		<v-row>
			<v-col>
				<v-autocomplete
						:items="items"
						:loading="!itemsLoaded"
						:disabled="!itemsLoaded || modelInfo !== null"
						v-model="selectedModels"
						variant="outlined"
						placeholder="Models"
						item-title="only_fans_model.nickname"
						item-value="id"
						density="compact"
						item-color="primary"
						chips
						clearable
						:error-messages="errors.only_fans_models"
				>
<!--multiple		closable-chips			-->
					<template #prepend>
						<div class="text-body-1 font-weight-medium">Tag model:</div>
					</template>
					<template #chip="{ props }">
						<v-chip v-bind="props" color="primary"></v-chip>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="auto">
				<v-btn
						height="44"
						width="100"
						size="large"
						color="primary"
						@click="post"
						:loading="sendLoading"
				>Post</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<style scoped>

</style>