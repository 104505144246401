import axios from "@/services/axios-instance";

export default {
  async getPeriods() {
    const response = await axios.get('/v1/finance/budget/periods/');
    return response.data
  },

  async getPeriod(id) {
    const response = await axios.get(`/v1/finance/budget/periods/${id}/`);
    return response.data
  },

  async addPeriod(data) {
    const response = await axios.post('/v1/finance/budget/periods/', data);
    return response.data
  },

  async editPeriod(id, data){
    const response = await axios.patch(`/v1/finance/budget/periods/${id}/`, data);
    return response.data
  },

  async addPlanDepartment(data) {
    const response = await axios.post('/v1/finance/budget/budgets/', data);
    return response.data
  },

  async editPlanDepartment(id, data) {
    const response = await axios.patch(`/v1/finance/budget/budgets/${id}/`, data);
    return response.data
  },

  async deletePlanDepartment(id) {
    const response = await axios.delete(`/v1/finance/budget/budgets/${id}/`);
    return response.data
  },
}