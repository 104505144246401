<script>
import apiManagers from "@/api/managers";
import {useDisplay} from "vuetify";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();

		return { lgAndUp, mdAndDown }
	},


	name: "DatabookInfoEditMode",


	props: {
		modelInfo: {},
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			editLoading: false,
			image: null,
			base64: null,
			whatever: [],
			addLinkDialogShow: false,
			addSubprofileDialogShow: false,
			activeLinkDialogType: null,
			selectedSocial: null,
			selectedLink: null,
			selectedModel: null,
			info: {},
			nationalities: [],
			nationalitiesLoaded: false,
			nationalitiesLoading: false,
			languages: [],
			languagesLoaded: false,
			languagesLoading: false,
			searchLanguages: '',
			countries: [],
			countriesLoaded: false,
			countriesLoading: false,
			socials: [],
			socialsLoaded: false,
			socialsLoading: false,
			errors: {}
		}
	},


	created() {
		this.info = structuredClone(this.modelInfo);
		(this.modelInfo.dob !== null) ? this.info.dob = new Date(this.modelInfo.dob) : this.info.dob === null;
		this.info.languages = this.modelInfo.languages.map(item => item.id);
        (this.modelInfo.country) ? this.info.country = this.modelInfo.country.id : this.info.country = null;
		(this.modelInfo.nationality) ? this.info.nationality = this.modelInfo.nationality.id : this.info.nationality = null;
		(this.modelInfo.yes_list !== '') ? this.info.yes_list = this.modelInfo.yes_list.split(', ') : this.info.yes_list = [];
		(this.modelInfo.stop_list !== '') ? this.info.stop_list = this.modelInfo.stop_list.split(', ') : this.info.stop_list = [];
		(this.modelInfo.like !== '') ? this.info.like = this.modelInfo.like.split(', ') : this.info.like = [];
		(this.modelInfo.dont_like !== '') ? this.info.dont_like = this.modelInfo.dont_like.split(', ') : this.info.dont_like = [];
		this.getModelNationalities();
		this.getModelLanguages();
		this.getModelCountries();
		this.getSocials();
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	watch: {
		image: function (value) {
			console.log(value.length)
			if(value && value.length !== 0) {
				this.createBase64Image(value[0]);
			} else {
				this.base64 = null;
			}
		}
	},


	methods: {
		formatingDate(date, format) {
			const resetDate = new Date(date);
			const year = resetDate.getFullYear();
			const month = (resetDate.getMonth() + 1).toString().padStart(2, '0');
			const day = (resetDate.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},

		removeObject(type, index) {
			if (type === 'contact') {
				this.info.contacts.splice(index, 1)
				if (this.errors.contacts) {
					this.errors.contacts.splice(index, 1)
				}
			}
			if (type === 'platform') {
				this.info.platforms.splice(index, 1)
				if (this.errors.platforms) {
					this.errors.platforms.splice(index, 1)
				}
			}
			if (type === 'sub_profile') {
				this.info.sub_profiles.splice(index, 1)
			}
		},

		openAddLinkDialog(type) {
			this.addLinkDialogShow = true;
			this.activeLinkDialogType = type;
		},

		addLink() {
			if (this.activeLinkDialogType === 'contact') {
				this.info.contacts.push({contact_url: this.selectedLink, social: this.selectedSocial})
			}
			if (this.activeLinkDialogType === 'platform') {
				this.info.platforms.push({platform_url: this.selectedLink, social: this.selectedSocial})
			}
			this.addLinkDialogShow = false;
			this.activeLinkDialogType = null;
			this.selectedSocial = null;
			this.selectedLink = null;
		},

		closeAddLinkDialog() {
			this.addLinkDialogShow = false;
			this.activeLinkDialogType = null;
			this.selectedSocial = null;
			this.selectedLink = null;
		},

		openAddSubprofileDialog() {
			this.addSubprofileDialogShow = true;
		},

		addSubprofile() {
			this.info.sub_profiles.push({id: this.selectedModel.id, nickname: this.selectedModel.nickname})
			this.addSubprofileDialogShow = false;
			this.selectedModel = null;
		},

		closeAddSubprofileDialog() {
			this.addSubprofileDialogShow = false;
			this.selectedModel = null;
		},

		async getModelNationalities() {
			try {
				this.nationalitiesLoaded = false;
				this.nationalitiesLoading = true;
				this.nationalities = await apiManagers.getModelNationalities();
				this.nationalitiesLoaded = true;
				this.nationalitiesLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getModelLanguages() {
			try {
				this.languagesLoaded = false;
				this.languagesLoading = true;
				this.languages = await apiManagers.getModelLanguages();
				this.languagesLoaded = true;
				this.languagesLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getModelCountries() {
			try {
				this.countriesLoaded = false;
				this.countriesLoading = true;
				this.countries = await apiManagers.getModelCountries();
				this.countriesLoaded = true;
				this.countriesLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getSocials() {
			try {
				this.socialsLoaded = false;
				this.socialsLoading = true;
				this.socials = await apiManagers.getSocials();
				this.socialsLoaded = true;
				this.socialsLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async editDatabookDetail() {
			this.editLoading = true;
			let data = {
				nationality: this.info.nationality,
				model_name: this.info.model_name,
				dob: this.formatingDate(this.info.dob, 'yyyy-MM-dd'),
				country: this.info.country,
				languages: this.info.languages,
				model_conditions: this.info.model_conditions,
				another_job: this.info.another_job,
				yes_list: this.info.yes_list.join(', '),
				stop_list: this.info.stop_list.join(', '),
				like: this.info.like.join(', '),
				dont_like: this.info.dont_like.join(', '),
				hobby: this.info.hobby,
				dreams: this.info.dreams,
				goals: this.info.goals,
				other: this.info.other,
			}
			if (this.base64) {
				data.avatar = this.base64
			}
			let contacts = [];
			this.info.contacts.forEach((item) => { contacts.push({contact_url: item.contact_url, social: item.social.id}) })
			data.contacts = contacts;
			let platforms = [];
			this.info.platforms.forEach((item) => { platforms.push({platform_url: item.platform_url, social: item.social.id}) })
			data.platforms = platforms;
			let sub_profiles = [];
			this.info.sub_profiles.forEach((item) => { sub_profiles.push(item.id) })
			data.sub_profiles = sub_profiles;
			try {
				const response = await apiManagers.editDatabookDetail(this.info.id, data);
				this.$emit('updateInfo', response);
				this.$emit('disableEditMode');
			} catch (error) {
				this.errors = error.response.data
				console.log(error)
			} finally {
				this.editLoading = false;
			}
		},

		createBase64Image(FileObject) {
			const reader = new FileReader();
			reader.onload = (event) => {
				this.base64 = event.target.result;
			}
			reader.readAsDataURL(FileObject);
		},

		delimit(value) {
			const reducer = (a, e) => [...a, ...e.split(/[, ]+/)];
			this.whatever = [
				...new Set( value.reduce(reducer, []) )
			]
		}
	}
}
</script>

<template>
	<div class="pb-16">


		<v-sheet class="mb-6 pa-4 pr-6 rounded-lg">
			<v-row align="center">
				<v-col cols="auto" class="mt-n6">
					<v-avatar v-if="info.avatar" size="90" class="rounded-lg">
						<v-img cover :src="info.avatar"></v-img>
					</v-avatar>
                    <v-avatar v-else size="90" color="primary">
                        <v-icon size="32">mdi-account</v-icon>
                    </v-avatar>
					<template v-if="base64">
						<v-icon color="primary">mdi-chevron-right</v-icon>
						<v-avatar size="90" class="rounded-lg">
							<v-img cover :src="base64"></v-img>
						</v-avatar>
					</template>
				</v-col>
				<v-col cols="6">
					<div class="text-h6 font-weight-black">{{ info.only_fans_model.nickname }}</div>
					<div class="mt-2">
						<v-file-input
								variant="outlined"
								accept="image/*"
								label="Change avatar"
								v-model="image"
								prepend-icon="mdi-camera"
						></v-file-input>
					</div>
				</v-col>
			</v-row>
		</v-sheet>


		<v-row>


			<v-col :cols="mdAndDown ? '12' : '4'">

				<v-row>

					<v-col cols="12">
						<v-sheet elevation="1" rounded class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">About</div>
							<div class="mb-4">
								<v-text-field
										v-model="info.model_name"
										variant="outlined"
										label="Name"
										hide-details="auto"
								></v-text-field>
							</div>
							<div class="mb-4">
								<VueDatePicker
										v-model="info.dob"
										placeholder="Date of birth"
										auto-apply
										:format="format"
								/>
							</div>
							<div class="mb-4">
								<v-text-field
										v-model="info.age"
										variant="outlined"
										label="Age"
										hide-details="auto"
										disabled
								></v-text-field>
							</div>
							<div class="mb-4">
								<v-text-field
										v-model="info.zodiac_sign"
										variant="outlined"
										label="Zodiac sign"
										hide-details="auto"
										disabled
								></v-text-field>
							</div>
							<div class="mb-4">
								<v-autocomplete
										:items="nationalities"
										v-model="info.nationality"
										:loading="!nationalitiesLoaded"
										:disabled="!nationalitiesLoaded"
										item-value="id"
										item-title="nationality_name"
										variant="outlined"
										label="Nationality"
										hide-details="auto"
										clearable
								></v-autocomplete>
							</div>
							<div class="mb-4">
								<v-autocomplete
										:items="countries"
										v-model="info.country"
										:loading="!countriesLoaded"
										:disabled="!countriesLoaded"
										item-value="id"
										item-title="country_name"
										variant="outlined"
										label="Live"
										hide-details="auto"
										clearable
								></v-autocomplete>
							</div>
							<div class="mb-4">
								<v-autocomplete
										:items="languages"
										v-model="info.languages"
										:loading="!languagesLoaded"
										:disabled="!languagesLoaded"
										item-value="id"
										item-title="language_name"
										variant="outlined"
										label="Languages"
										hide-details="auto"
										multiple
										clearable
										v-model:search.sync="searchLanguages"
										@update:modelValue="searchLanguages = ''"
								></v-autocomplete>
							</div>
							<div class="mb-8">
								<v-text-field
										v-model="info.another_job"
										variant="outlined"
										label="Another job/University"
										hide-details="auto"
								></v-text-field>
							</div>
							<div class="text-subtitle-1 font-weight-black mb-3 mt-8">Contact information</div>
							<template v-if="info.contacts.length > 0">
								<v-row
										class="mb-1 px-2 mx-n2 rounded"
										:class="errors.contacts && errors.contacts[index] && Object.keys(errors.contacts[index]).length > 0 ? 'bg-red-lighten-4 ' : ''"
										v-for="(item, index) in info.contacts"
										:key="index"
										align="center"
										no-gutters
								>
									<v-col>
										<div class="d-flex align-center">
											<img width="24" class="mr-2" :src="item.social.icon" :alt="item.social.name">
											<span class="text-caption text-grey-darken-1">{{ item.contact_url}}</span>
										</div>
									</v-col>
									<v-col cols="auto">
										<v-btn icon="mdi-trash-can-outline" density="comfortable" variant="text" @click="removeObject('contact', index)"></v-btn>
									</v-col>
									<v-col v-if="errors.contacts && errors.contacts[index] && Object.keys(errors.contacts[index]).length > 0" cols="12">
										<div class="py-1 text-error">{{ errors.contacts[index].contact_url[0] }}</div>
									</v-col>
								</v-row>
							</template>
							<div class="mt-5">
								<label>
									<v-btn icon="mdi-plus" size="small" density="comfortable" color="primary" @click="openAddLinkDialog('contact')"></v-btn>
									<span class="text-body-2 ml-2 font-weight-bold">Add contact</span>
								</label>
							</div>
						</v-sheet>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Models present, conditions</div>
							<v-textarea
									v-model="info.model_conditions"
									rows="1"
									variant="outlined"
									label="Models present, conditions"
									hide-details="auto"
									auto-grow
							></v-textarea>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Platforms</div>
							<template v-if="info.platforms.length > 0">
								<v-row
										class="mb-1 px-2 mx-n2 rounded"
										:class="errors.platforms && errors.platforms[index] && Object.keys(errors.platforms[index]).length > 0 ? 'bg-red-lighten-4 ' : ''"
										v-for="(item, index) in info.platforms"
										:key="index"
										align="center"
										no-gutters
								>
									<v-col>
										<div class="d-flex align-center">
											<img width="24" class="mr-2" :src="item.social.icon" :alt="item.social.name">
											<span class="text-caption text-grey-darken-1">{{ item.platform_url}}</span>
										</div>
									</v-col>
									<v-col cols="auto">
										<v-btn icon="mdi-trash-can-outline" density="comfortable" variant="text" @click="removeObject('platform', index)"></v-btn>
									</v-col>
									<v-col v-if="errors.platforms && errors.platforms[index] && Object.keys(errors.platforms[index]).length > 0" cols="12">
										<div class="py-1 text-error">{{ errors.platforms[index].platform_url[0] }}</div>
									</v-col>
								</v-row>
							</template>
							<div class="mt-5">
								<label>
									<v-btn icon="mdi-plus" size="small" density="comfortable" color="primary" @click="openAddLinkDialog('platform')"></v-btn>
									<span class="text-body-2 ml-2 font-weight-bold">Add platform</span>
								</label>
							</div>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Subprofiles</div>
							<template v-if="info.sub_profiles.length > 0">
								<v-row
										v-for="(item, index) in info.sub_profiles"
										:key="index"
										align="center"
										no-gutters
								>
									<v-col>
										<div class="text-subtitle-1 font-weight-medium text-grey-darken-1">{{ item.nickname }}</div>
									</v-col>
									<v-col cols="auto">
										<v-btn icon="mdi-trash-can-outline" density="comfortable" variant="text" @click="removeObject('sub_profile', index)"></v-btn>
									</v-col>
								</v-row>
							</template>
							<div class="mt-5">
								<label>
									<v-btn icon="mdi-plus" size="small" density="comfortable" color="primary" @click="openAddSubprofileDialog"></v-btn>
									<span class="text-body-2 ml-2 font-weight-bold">Add subprofile</span>
								</label>
							</div>
						</v-card>
					</v-col>

				</v-row>

			</v-col>


			<v-col :cols="mdAndDown ? '12' : '8'">

				<v-row class="mb-3">

					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Yes list</div>
							<v-combobox
									v-model="info.yes_list"
									rows="1"
									auto-grow
									variant="outlined"
									:delimiters="[',']"
									validate-on-blur
									closable-chips
									hide-details="auto"
									multiple
									chips
							>
								<template #chip="{ props }">
									<v-chip v-bind="props" color="teal"></v-chip>
								</template>
							</v-combobox>
						</v-card>
					</v-col>

					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Stop list</div>
							<v-combobox
									v-model="info.stop_list"
									rows="1"
									auto-grow
									variant="outlined"
									:delimiters="[',']"
									validate-on-blur
									closable-chips
									hide-details="auto"
									multiple
									chips
							>
								<template #chip="{ props }">
									<v-chip v-bind="props" color="pink-darken-1"></v-chip>
								</template>
							</v-combobox>
						</v-card>
					</v-col>

					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Like</div>
							<v-combobox
									v-model="info.like"
									rows="1"
									auto-grow
									variant="outlined"
									:delimiters="[',']"
									validate-on-blur
									closable-chips
									hide-details="auto"
									multiple
									chips
							>
								<template #chip="{ props }">
									<v-chip v-bind="props" color="teal"></v-chip>
								</template>
							</v-combobox>
						</v-card>
					</v-col>

					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Dont like</div>
							<v-combobox
									v-model="info.dont_like"
									rows="1"
									auto-grow
									variant="outlined"
									:delimiters="[',']"
									validate-on-blur
									closable-chips
									hide-details="auto"
									multiple
									chips
							>
								<template #chip="{ props }">
									<v-chip v-bind="props" color="pink-darken-1"></v-chip>
								</template>
							</v-combobox>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Hobby</div>
							<v-textarea
									v-model="info.hobby"
									rows="1"
									variant="outlined"
									label="Hobby"
									hide-details="auto"
									auto-grow
							></v-textarea>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Dreams</div>
							<v-textarea
									v-model="info.dreams"
									rows="1"
									variant="outlined"
									label="Dreams"
									hide-details="auto"
									auto-grow
							></v-textarea>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Goals</div>
							<v-textarea
									v-model="info.goals"
									rows="1"
									variant="outlined"
									label="Goals"
									hide-details="auto"
									auto-grow
							></v-textarea>
						</v-card>
					</v-col>

					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Other information</div>
							<v-textarea
									v-model="info.other"
									rows="1"
									variant="outlined"
									label="Other information"
									hide-details="auto"
									auto-grow
							></v-textarea>
						</v-card>
					</v-col>

				</v-row>

			</v-col>


		</v-row>

		<v-dialog v-model="addLinkDialogShow" width="400">
			<v-card class="py-4 px-6">
				<div class="text-body-1 font-weight-bold mb-6">
					<template v-if="activeLinkDialogType === 'contact'">Add contact information</template>
					<template v-if="activeLinkDialogType === 'platform'">Add platform</template>
				</div>
				<div class="text-subtitle-2 mb-3">URL link</div>
				<v-text-field
						class="mb-6"
						v-model="selectedLink"
						variant="outlined"
						hide-details="auto"
				></v-text-field>
				<div class="text-subtitle-2 mb-3">Platform type</div>
				<v-autocomplete
						:items="socials"
						v-model="selectedSocial"
						:loading="!socialsLoaded"
						:disabled="!socialsLoaded"
						item-value="id"
						item-title="name"
						variant="outlined"
						hide-details="auto"
						return-object
				>
					<template v-slot:selection="{ item }">
						<div class="d-flex align-center">
							<img width="24" class="mr-2" :src="item.value.icon" :alt="item.title">
							<span class="text-caption text-grey-darken-1">{{ item.title}}</span>
						</div>
					</template>
					<template #item="{ props, item }">
						<v-list-item :prepend-avatar="item.value.icon" v-bind="props" :title="item.title"></v-list-item>
					</template>
				</v-autocomplete>
				<v-row class="mt-6" justify="end">
					<v-col cols="auto">
						<v-btn variant="text" class="mr-2" @click="closeAddLinkDialog">Cancel</v-btn>
						<v-btn color="primary" @click="addLink">Confirm</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<v-dialog v-model="addSubprofileDialogShow" width="400">
			<v-card class="py-4 px-6">
				<div class="text-body-1 font-weight-bold mb-6">Add subprofile</div>
				<div class="text-subtitle-2 mb-3">Select model</div>
				<v-autocomplete
						:items="commonListModels"
						v-model="selectedModel"
						:loading="!commonListModelsLoaded"
						:disabled="!commonListModelsLoaded"
						item-value="id"
						item-title="nickname"
						variant="outlined"
						hide-details="auto"
						return-object
				>
					<template v-slot:item="data">
						<v-list-item
								:class="data.item.raw.id === modelInfo.only_fans_model.id ? 'current-model' : ''"
								v-bind="data.props"
								:title="data.props.title"
						>
						</v-list-item>
					</template>
				</v-autocomplete>
				<v-row class="mt-6" justify="end">
					<v-col cols="auto">
						<v-btn
								variant="text"
								class="mr-2"
								@click="closeAddSubprofileDialog"
						>Cancel</v-btn>
						<v-btn
								color="primary"
								@click="addSubprofile"
						>Confirm</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<div class="elevation-3 position-fixed w-100 bg-white pa-6 d-flex justify-end" style="bottom: 0; right: 0; z-index: 1; padding-left: 200px !important;">
			<v-btn
					:disabled="editLoading"
					variant="text"
					class="mr-2"
					@click="$emit('disableEditMode')"
			>Cancel</v-btn>
			<v-btn
					:loading="editLoading"
					:disabled="editLoading"
					color="primary"
					@click="editDatabookDetail"
			>Save changes</v-btn>
		</div>
	</div>
</template>

<style lang="less">
.current-model {
	pointer-events: none;
	opacity: .4;
}
</style>