<script>
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: "PayoutEditDialog",


	props: {
		edited: {},
		info: {}
	},


	data() {
		return {
			formLoading: false,
			format: 'dd.MM.yyyy',
			fields: {},
			errors: {},
			addresses: [],
			addressesLoading: false,
			addressesLoaded: false,
		}
	},


	created() {
		this.getPayoutAddresses();
		this.setFields();
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	methods: {

		async getPayoutAddresses() {
			this.addressesLoading = true;
			this.addressesLoaded = false;
			this.addresses = await apiFinance.getPayoutAddresses()
			this.addressesLoading = false;
			this.addressesLoaded = true;
		},

		setFields() {
			this.fields.status = structuredClone(this.info.finance_review_result);
			if (this.info.payout_creation_date) {
				if (this.info.payout_id) {
					this.fields.payout_creation_date = new Date(this.info.payout_creation_date).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit'});
				} else {
					this.fields.payout_creation_date = this.info.payout_creation_date
				}
			} else {
				this.fields.payout_creation_date = null
			}
			if (this.info.only_fans_model) {
				if (this.info.payout_id) {
					this.fields.only_fans_model = this.info.only_fans_model?.nickname;
				} else {
					this.fields.only_fans_model = this.info.only_fans_model.id;
				}
			} else {
				this.fields.only_fans_model = null
			}
			this.fields.amount = structuredClone(this.info.amount);
			this.fields.financier = structuredClone(this.userInfo.full_name);

			if (this.info.payout_address) {
				this.fields.payout_address = structuredClone(this.info.payout_address.address_name);
			} else if (localStorage.last_address) {
				this.fields.payout_address = localStorage.last_address
			} else {
				this.fields.payout_address = null
			}
		},

		async handlePayoutUpdate() {
			this.errors = {};
			this.formLoading = true;
			try {
				let data = {};
				if (this.info.payout_id) {
					data = {
						finance_review_result: this.fields.status,
						payout_address: this.fields.payout_address,
					}
				} else {
					data = {
						finance_review_result: this.fields.status,
						amount: this.fields.amount,
						only_fans_model: this.fields.only_fans_model,
						payout_address: this.fields.payout_address,
					}
				}
				const response = await apiFinance.patchPayout(this.info.id, data);
				localStorage.last_address = this.fields.payout_address;
				this.$emit('update', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},

		async handlePayoutCreate() {
			this.errors = {};
			this.formLoading = true;
			try {
				const data = {
					finance_review_result: this.fields.status,
					payout_creation_date: this.fields.payout_creation_date,
					amount: this.fields.amount,
					only_fans_model: this.fields.only_fans_model,
					payout_address: this.fields.payout_address,
				};
				const response = await apiFinance.addPayout(data);
				localStorage.last_address = this.fields.payout_address;
				this.$emit('update', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		}
	}
}
</script>

<template>
	<v-card class="pa-6" :disabled="formLoading">
		<v-form class="fill-height d-flex flex-column" @submit.prevent>

			<div class="flex-0-0">
				<div v-if="edited === -1" class="mb-4 text-h5">New payout</div>
				<div v-else-if="info.payout_id" class="mb-4 text-h5">Payout id: {{ info.payout_id }}</div>
				<div v-else class="mb-4 text-h5">Edit payout</div>
				<v-divider></v-divider>
			</div>

			<div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">
				<v-stepper editable class="elevation-0" v-model="fields.status">
					<v-stepper-header>
						<v-stepper-item
								:disabled="info.finance_review_result !== 'review'"
								value="review"
								color="white"
								title="To work"
								edit-icon="mdi-pencil"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="reject"
								color="amber-lighten-4"
								title="Canceled"
								edit-icon="mdi-exclamation-thick"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="accept"
								color="green-lighten-4"
								title="Provided"
								edit-icon="mdi-check"
						></v-stepper-item>
					</v-stepper-header>
				</v-stepper>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Payout info</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<template v-if="edited === -1 || !info.payout_id">
								<div class="text-body-1 mb-2">Creation date</div>
								<VueDatePicker
										class="clearable-false"
										v-model="fields.payout_creation_date"
										auto-apply
										:format="format"
										:clearable="false"
										:class="errors.payout_creation_date ? 'mb-1' : 'mb-6'"
										:state="(errors.payout_creation_date) ? !errors.payout_creation_date : null"
								/>
								<v-slide-y-transition leave-absolute>
									<div class="pl-4 text-error text-caption" v-if="errors.payout_creation_date" transition="slide-x-transition" style="height: 20px">{{ errors.payout_creation_date[0] }}</div>
								</v-slide-y-transition>
							</template>
							<template v-else>
								<div class="text-body-1 mb-2 text-grey">Creation date</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Creation date"
										v-model="fields.payout_creation_date"
										prepend-inner-icon="mdi-calendar"
								></v-text-field>
							</template>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<template v-if="edited === -1 || !info.payout_id">
								<div class="text-body-1 mb-2">Model username</div>
								<v-autocomplete
										:items="commonListModels"
										:loading="!commonListModelsLoaded"
										:disabled="!commonListModelsLoaded"
										v-model="fields.only_fans_model"
										variant="outlined"
										placeholder="Model"
										item-title="nickname"
										item-value="id"
										:error-messages="errors.only_fans_model"
								></v-autocomplete>
							</template>
							<template v-else>
								<div class="text-body-1 mb-2 text-grey">Model username</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Model username"
										v-model="fields.only_fans_model"
								></v-text-field>
							</template>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<template v-if="edited === -1 || !info.payout_id">
								<div class="text-body-1 mb-2 text-grey">Amount</div>
								<v-text-field
										variant="outlined"
										placeholder="Amount"
										v-model="fields.amount"
										:error-messages="errors.amount"
								></v-text-field>
							</template>
							<template v-else>
								<div class="text-body-1 mb-2 text-grey">Amount</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Amount"
										v-model="fields.amount"
								></v-text-field>
							</template>
						</v-col>
					</v-row>
				</div>

				<v-divider></v-divider>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Provide detail</div>
					<v-row class="flex-wrap" style="max-width: 700px">
						<v-col cols="12">
							<div class="text-body-1 mb-2">Financier</div>
							<v-text-field disabled readonly placeholder="Financier" v-model="fields.financier" variant="outlined"></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Payout address</div>
							<v-combobox
									variant="outlined"
									item-title="address_name"
									item-value="address_name"
									placeholder="Payout address"
									:loading="!addressesLoaded"
									:disabled="!addressesLoaded"
									:items="addresses"
									v-model="fields.payout_address"
									:return-object="false"
									:error-messages="errors.payout_address"
							></v-combobox>
						</v-col>
					</v-row>
				</div>

			</div>

			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="pt-4">
					<v-btn v-if="edited === -1" class="ml-2" color="primary" @click="handlePayoutCreate" :disabled="formLoading" :loading="formLoading">Create</v-btn>
					<v-btn v-else class="ml-2" color="primary" @click="handlePayoutUpdate" :disabled="formLoading" :loading="formLoading">Save</v-btn>
					<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
				</v-row>
			</div>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>