<template>
	<v-card class="pa-6">
		<v-form v-model="form" class="fill-height d-flex flex-column" @submit.prevent>
			<div class="flex-0-0">
				<div class="mb-4">
					<template v-if="checkMonthChanging">
						<span class="text-grey-darken-1">{{ formTitle }}</span><span class="text-h5 mb-4"><v-icon class="mx-2" size="24">mdi-arrow-right</v-icon>{{monthName}} {{month.year}}</span>
					</template>
					<template v-else>
						<span class="text-h5">{{ formTitle }}</span>
					</template>
				</div>
				<v-divider></v-divider>
			</div>
			<div class="flex-1-1 overflow-y-auto">
				<div class="py-8 px-3" :class="mdAndDown ? 'overflow-x-auto mx-n3' : ''" :style="mdAndDown ? 'width: 1100px;' : ''">
					<v-row align="center" class="mb-6 flex-nowrap">
						<v-col cols="auto" class="flex-1-1">
							<v-row align="center">
								<v-col cols="7" class="pr-14">
									<div class="d-flex">
										<VueDatePicker
												class="width-auto"
												placeholder="Select month"
												auto-apply
												v-model="month"
												month-picker
												position="left"
										>
											<template #trigger>
												<v-btn
														class="mr-6"
														variant="outlined"
														color="primary"
														density="comfortable"
														prepend-icon="mdi-pencil"
												>{{ edited !== -1 ? 'Change month' : 'Select month' }}</v-btn>
											</template>
											<template #input-icon>
												<v-icon class="pl-4 mr-1" size="18">mdi-pencil</v-icon>
											</template>
										</VueDatePicker>
										<label class="d-inline-flex align-center">
											<v-btn
													:disabled="!previousPeriodId"
													variant="outlined"
													color="primary"
													density="comfortable"
													min-width="36"
													class="px-2"
													@click="dialogCopy = true"
											>
												<v-icon>mdi-content-copy</v-icon>
											</v-btn>
											<span class="text-caption ml-2">Copy data from previous month</span>
										</label>
									</div>
								</v-col>
								<v-col v-if="previousPeriod" cols="5" class="pl-2">
									<div class="text-subtitle-1 font-weight-medium">{{ previousPeriod.name }}</div>
								</v-col>
							</v-row>
						</v-col>
						<v-col style="width: 60px" class="flex-0-0"></v-col>
					</v-row>

					<template v-if="items.length > 0">

						<v-row align="center" class="flex-nowrap">
							<v-col cols="auto" class="flex-1-1">
								<v-row align="center">
									<v-col cols="7" class="pr-14">
										<v-row align="center">
											<v-col cols="4"></v-col>
											<v-col cols="4">
												<div class="text-body-1">Expected plan</div>
											</v-col>
											<v-col cols="4">
												<div class="text-body-1">Expected wage fund</div>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="5" class="pl-2">
										<v-row align="center" v-if="previousPeriod">
											<v-col cols="12">
												<div class="text-body-1">Spent</div>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-col>
							<v-col style="width: 60px" class="flex-0-0"></v-col>
						</v-row>

						<v-row align="start" v-for="(item, index) in items" :key="index" class="flex-nowrap">
							<v-col cols="auto" class="flex-1-1">
								<v-row align="center">
									<v-col cols="7" class="pr-14">
										<v-row align="start">
											<v-col class="plan-cell" cols="4">
												<span>{{ item.department.name }}</span>
											</v-col>
											<v-col cols="4">
												<v-text-field
														v-model="item.expected_plan"
														density="compact"
														variant="outlined"
														placeholder="Expected plan"
														type="number"
														:rules="[rules.required, rules.notNull]"
														prepend-inner-icon="mdi-currency-usd"
														hide-details="auto"
														@update:model-value="fieldsErrors.budgets[index] = {}"
														:error-messages="fieldsErrors.budgets[index]?.expected_plan"
												></v-text-field>
											</v-col>
											<v-col cols="4">
												<v-text-field
														v-model="item.expected_wage_fund"
														density="compact"
														variant="outlined"
														placeholder="Expected wage fund"
														type="number"
														:rules="[rules.required, rules.notNull]"
														prepend-inner-icon="mdi-currency-usd"
														hide-details="auto"
														@update:model-value="fieldsErrors.budgets[index] = {}"
														:error-messages="fieldsErrors.budgets[index]?.expected_wage_fund"
												></v-text-field>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="5"  class="pl-2">
										<template v-if="previousPeriod">
											<template v-if="targetDepartment(item.department.id)">
												<v-row align="start">
													<v-col class="plan-cell" cols="6">
														<div class="d-flex align-center">
															<span style="width: 70px" class="text-no-wrap">{{ previousBudgetFact(item.department.id) }}</span>
															<div style="width: 80px">
																<v-progress-linear
																		:model-value="previousBudgetFactPercent(item.department.id)"
																		:color="previousBudgetFactColor(item.department.id)"
																		rounded
																		height="12"
																></v-progress-linear>
															</div>
															<span style="text-overflow: ellipsis; width: 65px;" class="ml-2 text-no-wrap font-weight-bold overflow-hidden" :class="'text-' + previousBudgetFactColor(item.department.id)">{{ previousBudgetFactPercent(item.department.id) }}%</span>
														</div>
													</v-col>
													<v-col class="plan-cell" cols="6">
														<div class="d-flex align-center">
															<span style="width: 70px" class="text-no-wrap">{{ previousBudgetWageFundFact(item.department.id) }}</span>
															<div style="width: 80px">
																<v-progress-linear
																		:model-value="previousBudgetWageFundFactPercent(item.department.id)"
																		:color="previousBudgetWageFundFactColor(item.department.id)"
																		rounded
																		height="12"
																></v-progress-linear>
															</div>
															<span style="text-overflow: ellipsis; width: 65px;" class="ml-2 text-no-wrap font-weight-bold overflow-hidden" :class="'text-' + previousBudgetWageFundFactColor(item.department.id)">{{ previousBudgetWageFundFactPercent(item.department.id) }}%</span>
														</div>
													</v-col>
												</v-row>
											</template>
											<template v-else>
												<div class="text-center">No data for the previous period</div>
											</template>
										</template>
									</v-col>
								</v-row>
							</v-col>
							<v-col style="width: 60px" class="flex-0-0 plan-cell">
								<v-btn variant="flat" density="comfortable" icon="mdi-delete" @click="deleteDepartment(item)"></v-btn>
							</v-col>
						</v-row>

					</template>

					<template v-else>
						<div class="mt-10 text-body-1 text-center">
							<span>...</span><br>
							<span>Add a department to start filling out your plan</span>
						</div>
					</template>

					<div class="mt-6">
						<v-btn variant="outlined" density="comfortable" prepend-icon="mdi-plus" @click="dialogAddDepartment = true">Add department</v-btn>
					</div>
				</div>

				<v-dialog v-model="dialogAddDepartment" max-width="400px">
					<v-card class="pa-6">
						<div class="text-h5 mb-8">Add department</div>
						<v-form @submit.prevent>
							<v-row class="flex-wrap">
								<v-col>
									<v-autocomplete
											v-model="departmentToAdd"
											:items="departments"
											:loading="!departmentsLoaded"
											:disabled="!departmentsLoaded"
											variant="outlined"
											placeholder="Department"
											item-title="name"
											item-value="id"
											return-object
									></v-autocomplete>
								</v-col>
							</v-row>
							<v-row justify="end" no-gutters class="mt-4 pa-0">
								<v-btn variant="text" @click="closeAddDepartmentDialog" class="mr-2">Cancel</v-btn>
								<v-btn :disabled="!departmentToAdd" color="primary" @click="addDepartmentToPlan">Add</v-btn>
							</v-row>
						</v-form>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogDeleteDepartment" max-width="340px">
					<v-card class="pa-6">
						<div class="text-body-1 mb-2">Remove from plan</div>
						<div class="text-subtitle-2">Are you sure?</div>
						<v-row justify="end" no-gutters class="mt-10 pa-0">
							<v-btn color="primary" class="mr-2" variant="text" @click="closeDeleteDepartment">Cancel</v-btn>
							<v-btn color="primary" @click="deleteDepartmentConfirm">Confirm</v-btn>
						</v-row>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogCopy" max-width="340px">
					<v-card class="pa-6">
						<div class="text-body-1 mb-2">Copy previous month</div>
						<div class="text-subtitle-2">Fill in the fields with data from the previous month?</div>
						<v-row justify="end" no-gutters class="mt-10 pa-0">
							<v-btn color="primary" class="mr-2" variant="text" @click="dialogCopy = false">Cancel</v-btn>
							<v-btn color="primary" @click="copyPreviousPeriod">Confirm</v-btn>
						</v-row>
					</v-card>
				</v-dialog>

			</div>
			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="mt-4 pa-0">
					<v-btn
							v-if="edited !== -1"
							:loading="formLoading"
							type="submit"
							color="primary"
							@click="editPlan"
					>Save</v-btn>
					<v-btn
							v-else
							:loading="formLoading"
							type="submit"
							color="primary"
							@click="addPlan"
					>Create</v-btn>
					<v-btn
							variant="text"
							@click="$emit('close')"
							class="ml-2"
					>Cancel</v-btn>
				</v-row>
				<div v-if="errors" class="mt-4 text-error">{{errors}}</div>
			</div>
		</v-form>
	</v-card>
</template>

<script>
import apiBudgetFinance from '@/api/budget-finance';
import { mapState } from 'vuex';
import {useDisplay} from "vuetify";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'BudgetFinanceEditDialog',


	components: {

	},


	props: {
		edited: {
			type: Number
		},
		info: {
			type: Object
		},
		type: {},
		periods: {
			type: Array
		},
		rawDepartments: {
			type: Array
		},
		departmentsLoaded: {
			type: Boolean
		}
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === []) return 'required field';
					if (value) return true;
					return 'required field'
				},
				notNull: value => {
					if (value === 0 || value === '0') return 'Should not be equal to null';
				},
			},
			form: false,
			errors: null,
			fieldsErrors: {budgets: []},
			monthNamesList: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			formLoading: false,
			month: null,
			monthOldValue: null,
			format: 'MM/yyyy',
			dialogCopy: false,
			dialogAddDepartment: false,
			dialogDeleteDepartment: false,
			editedDepartmentIndex: -1,
			departmentToAdd: null,
			items: [],
			previousPeriodId: null,
			previousPeriodLoaded: false,
			previousPeriod: null,
			previousPeriodItems: [],
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),

		monthName() {
			return this.monthNamesList[this.month?.month]
		},

		formTitle() {
			return this.edited === -1 ? 'New month' : this.info.name
		},

		departments() {
			let departments = structuredClone(this.rawDepartments);
			this.items.forEach(target => {
				departments.forEach((department, index) => {
					if (target.department.name === department.name) {
						departments.splice(index, 1)
					}
				})
			})
			// let activeDepartments = departments.filter(elem => elem.marketer);
			return departments
		},

		checkMonthChanging() {
			if (this.edited !== -1) {
				return !(this.month.month === this.monthOldValue.month && this.month.year === this.monthOldValue.year)
			} else {
				return this.month && !this.monthOldValue
			}
		},

		noDataText() {
			if (this.rawDepartments.length === 0) {
				return 'Departments list is empty'
			} else if (this.departments.length === 0) {
				return 'All departments added'
			} else {
				return ''
			}
		},
	},


	created() {
		if (this.edited !== -1) {
			this.month = {
				month: new Date(this.info.start_date).getMonth(),
				year: new Date(this.info.start_date).getFullYear()
			};
			this.monthOldValue = {
				month: new Date(this.info.start_date).getMonth(),
				year: new Date(this.info.start_date).getFullYear()
			};
			this.getPreviousPeriod();
			this.items = structuredClone(this.info.budgets);
		}
		if (this.previousPeriodId) {
			this.getPeriod();
		}
	},


	watch: {
		month() {
			this.getPreviousPeriod();
			this.errors = null
		}
	},


	methods: {
		getPreviousPeriod() {
			let year = this.month.year;
			let month = this.month.month;
			if (month === 0) {
				year = year - 1
				month = 12
			}
			month = month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
			const previousMonth = `${year}-${month}-01`;
			this.previousPeriodId = this.periods.find(item => item.start_date === previousMonth)?.id;
			if (this.previousPeriodId) {
				this.getPeriod();
			} else {
				this.previousPeriod = null
			}
		},

		calcValue(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			return Math.round(value2 / value1 * 100);
		},

		calcColor(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			const result = Math.round(value2 / value1 * 100);
			if (result > 60) {
				return 'cyan-darken-3'
			} else if (result <= 60 && result > 20) {
				return 'amber-darken-2'
			} else if (result <=20) {
				return 'deep-orange-darken-1'
			}
		},

		closeAddDepartmentDialog() {
			this.dialogAddDepartment = false;
			this.departmentToAdd = null
		},

		addDepartmentToPlan() {
			const item = {
				department: this.departmentToAdd,
				expected_plan: 0,
				expected_wage_fund: 0
			}
			this.items.push(item)
			this.dialogAddDepartment = false;
			this.departmentToAdd = null
		},

		deleteDepartment(item) {
			this.editedDepartmentIndex = this.items.indexOf(item);
			this.dialogDeleteDepartment = true
		},

		closeDeleteDepartment() {
			this.dialogDeleteDepartment = false;
			this.$nextTick(() => {
				this.editedDepartmentIndex = -1
			})
		},

		async deleteDepartmentConfirm() {
			this.items.splice(this.editedDepartmentIndex, 1)
			this.closeDeleteDepartment();
		},

		copyPreviousPeriod() {
			this.items = structuredClone(this.previousPeriodItems);
			this.dialogCopy = false
		},

		async getPeriod() {
			try {
				this.previousPeriodLoaded = false;
				this.previousPeriod = await apiBudgetFinance.getPeriod(this.previousPeriodId);
				this.previousPeriodItems = this.previousPeriod.budgets;
				this.previousPeriodLoaded = true
			} catch (error) {
				console.log('previous period loading error: ', error)
			}
		},

		targetDepartment(id) {
			return this.previousPeriodItems.find(item => item.department.id === id)
		},

		previousBudgetFact(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return target.budget_fact
		},

		previousBudgetFactPercent(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return this.calcValue(target.expected_plan, target.budget_fact)
		},

		previousBudgetFactColor(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return this.calcColor(target.expected_plan, target.budget_fact)
		},

		previousBudgetWageFundFact(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return target.wage_fund_fact
		},

		previousBudgetWageFundFactPercent(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return this.calcValue(target.expected_wage_fund, target.wage_fund_fact)
		},

		previousBudgetWageFundFactColor(id) {
			const target = this.previousPeriodItems.find(item => item.department.id === id);
			return this.calcColor(target.expected_wage_fund, target.wage_fund_fact)
		},

		async addPlan() {
			if (!this.form) return
			if (this.month) {
				try {
					this.formLoading = true;
					const lastDayOfMonth = new Date(this.month.year, this.month.month + 1, 0).getDate();
					const budgets = []
					this.items.forEach(item => {
						budgets.push({
							department: item.department.id,
							expected_plan: item.expected_plan,
							expected_wage_fund: item.expected_wage_fund
						})
					});
					const data = {
						start_date: `${this.month.year}-${this.month.month + 1}-01`,
						end_date: `${this.month.year}-${this.month.month + 1}-${lastDayOfMonth}`,
						name: `${this.monthName} ${this.month.year}`,
						budgets: budgets
					}
					const response = await apiBudgetFinance.addPeriod(data);
					this.$emit('add', response)
					this.$emit('close')
				} catch (error) {
					console.log('error', error)
				} finally {
					this.formLoading = false;
				}
			} else {
				this.errors = 'Month not selected'
			}
		},

		async editPlan() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const lastDayOfMonth = new Date(this.month.year, this.month.month + 1, 0).getDate();
				const budgets = []
				let newName = null;
				if (this.month) {
					newName = `${this.monthName} ${this.month.year}`
				}
				this.items.forEach(item => {
					budgets.push({
						department: item.department.id,
						expected_plan: item.expected_plan,
						expected_wage_fund: item.expected_wage_fund,
					})
				});
				const data = {
					start_date: `${this.month.year}-${this.month.month + 1}-01`,
					end_date: `${this.month.year}-${this.month.month + 1}-${lastDayOfMonth}`,
					budgets: budgets,
				}
				if (newName) {
					data.name = newName
				}
				const response = await apiBudgetFinance.editPeriod(this.info.id, data);
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('error', error);
				const fieldsErrors = Object.keys(error.response.data);
				fieldsErrors.forEach((key) => {
					this.fieldsErrors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<style scoped lang="less">
.plan-cell {
	display: flex;
	align-items: center;
	min-height: 68px;
}
</style>