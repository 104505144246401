<script>
import {mapState} from "vuex";
import {useDisplay} from "vuetify";
import DatabookCard from "@/components/DatabookCard.vue";
import apiModels from "@/api/models";
import apiManagers from "@/api/managers";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();

		return { lgAndUp, mdAndDown }
	},


	name: "DatabookPage",


	components: {
		DatabookCard
	},


	data() {
		return {
			pageTitle: 'Databook',
			manager: [],
			genre: [],
			nationality: null,
			search: '',
			ordering: 'nickname',
			orderList: [
				{ name: 'A-z', value: 'nickname' },
				{ name: 'Last edit', value: 'updated_at' },
			],
			sortAlphabetical: true,
			sortLastEdit: true,
			genres: [],
			genresLoaded: false,
			genresLoading: false,
			nationalities: [],
			nationalitiesLoaded: false,
			nationalitiesLoading: false,
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
			searchManagers: '',
			searchGenres: '',
		}
	},


	computed: {
		...mapState({
			managers: state => state.managers.managers,
			managersLoading: state => state.managers.managersLoading,
			managersLoaded: state => state.managers.managersLoaded,
		}),
	},


	created() {
		this.getModelGenres();
		this.getModelNationalities();
		this.getDatabook();
	},


	watch: {
		manager() {
			this.getDatabook();
		},
		genre() {
			this.getDatabook();
		},
		ordering() {
			this.getDatabook();
		},
		nationality() {
			this.getDatabook();
		},
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getDatabook()
			}, 400);
		}
	},


	methods: {
		setOrdering(value) {
			if (this.ordering === '-' + value || this.ordering === value) {
				if (this.ordering === value) {
					this.ordering = '-' + value;
				} else {
					this.ordering = value;
				}
			} else {
				this.ordering = value;
			}
		},

		async getModelGenres() {
			try {
				this.genresLoaded = false;
				this.genresLoading = true;
				this.genres = await apiModels.getModelGenres();
				this.genresLoaded = true;
				this.genresLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getModelNationalities() {
			try {
				this.nationalitiesLoaded = false;
				this.nationalitiesLoading = true;
				this.nationalities = await apiManagers.getModelNationalities();
				this.nationalitiesLoaded = true;
				this.nationalitiesLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},

		async getDatabook() {
			this.itemsLoading = true;
			this.itemsLoaded = false;
			try {
				let params = {
					categories: this.genre.join(','),
					client_managers: this.manager.join(','),
					nationality: this.nationality,
					search: this.search,
					ordering: this.ordering,
				}
				this.items = await apiManagers.getDatabook(params);
				this.itemsLoading = false;
				this.itemsLoaded = true;
			} catch (error) {
				console.log('error', error)
			}
		},
	}
}
</script>

<template>
	<div class="page-container pt-4 pb-10">
		<div class="page-wrap">

			<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

			<v-row justify="space-between">
				<v-col :cols="mdAndDown ? '12' : ''" :style="mdAndDown ? '' : 'max-width: 850px'">
					<v-row>
						<v-col :cols="mdAndDown ? '12' : '4'">
							<v-autocomplete
									v-model="manager"
									:items="managers"
									:loading="!managersLoaded"
									:disabled="!managersLoaded"
									item-value="id"
									item-title="full_name"
									label="Client manager"
									variant="outlined"
									hide-details
									density="compact"
									multiple
									clearable
									v-model:search.sync="searchManagers"
									@update:modelValue="searchManagers = ''"
							>
								<template v-slot:selection="{ index }">
									<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ manager.length }}</span>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? '12' : '4'">
							<v-autocomplete
									v-model="genre"
									:items="genres"
									:loading="!genresLoaded"
									:disabled="!genresLoaded"
									item-value="id"
									item-title="name"
									label="Genre"
									variant="outlined"
									hide-details
									density="compact"
									multiple
									clearable
									v-model:search.sync="searchGenres"
									@update:modelValue="searchGenres = ''"
							>
								<template v-slot:selection="{ index }">
									<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ genre.length }}</span>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? '12' : '4'">
							<v-autocomplete
									v-model="nationality"
									:items="nationalities"
									:loading="!nationalitiesLoaded"
									:disabled="!nationalitiesLoaded"
									item-value="id"
									item-title="nationality_name"
									label="Nationality"
									variant="outlined"
									hide-details
									density="compact"
									clearable
							></v-autocomplete>
						</v-col>
					</v-row>
				</v-col>
				<v-col :cols="mdAndDown ? '12' : ''" :style="mdAndDown ? '' : 'max-width: 320px'">
					<v-text-field
							v-model="search"
							append-inner-icon="mdi-magnify"
							label="Search"
							variant="underlined"
							hide-details
							density="compact"
					></v-text-field>
				</v-col>
			</v-row>

			<v-row justify="end">
				<v-col cols="auto">
					<v-btn
							v-for="(item, index) in orderList"
							:key="index"
							variant="text"
							class="text-none"
							:prepend-icon="ordering === '-' + item.value ? 'mdi-arrow-up' : 'mdi-arrow-down'"
							:class="(ordering === item.value || ordering === '-' + item.value) ? 'text-primary' : ''"
							@click="setOrdering(item.value)"
					>
						{{ item.name }}
					</v-btn>
				</v-col>
			</v-row>

			<v-divider class="my-4"></v-divider>

			<template v-if="!itemsLoaded">
				<v-row>
					<v-col v-for="(item, index) in 3" :key="index">
						<v-card>
							<v-skeleton-loader
									type="list-item-avatar, article"
							></v-skeleton-loader>
						</v-card>
					</v-col>
				</v-row>
			</template>

			<template v-else>
				<v-row v-if="items.length > 0">
					<v-col :cols="mdAndDown ? '12' : '4'" v-for="(item, index) in items" :key="index">
						<router-link :to="{name: 'DatabookDetail', params: { id: item.id }}">
							<DatabookCard :info="item" />
						</router-link>
					</v-col>
				</v-row>
				<div v-else class="mt-10 text-body-1 text-center">
					<span>...</span><br>
					<span>There is no data matching the selected filters</span>
				</div>
			</template>

		</div>
	</div>
</template>

<style scoped>

</style>