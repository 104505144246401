<script>
import {mapState} from "vuex";

export default {
	name: "CustomRequestEditDialog",


	data: () => ({
		formTitle: 'Александр Сергеев',
		formLoading: false,
		format: 'dd.MM.yyyy',
		fields: {
			description: ''
		},
		errors: {}
	}),


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},
}
</script>

<template>
	<v-card class="pa-6">
		<div class="mb-8"><span class="text-h5">{{ formTitle }}</span><span class="pl-2 text-subtitle-1">new custom request</span></div>
		<v-row align="center" justify="space-between" no-gutters class="mt-4 pa-0">
			<v-col cols="auto">
				<div class="d-flex">
					<v-icon size="20" color="red-accent-2">mdi-alert-circle</v-icon>
					<span class="pl-2 text-body-2">Deadline: 16.10.2024</span>
				</div>
			</v-col>
			<v-col cols="auto">
				<div class="d-flex align-center">
					<v-btn variant="text" @click="$emit('close')" class="mr-2">Cancel</v-btn>
					<v-btn :loading="formLoading" type="submit" color="primary">Create</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-autocomplete
						:items="commonListModels"
						:loading="!commonListModelsLoaded"
						:disabled="!commonListModelsLoaded"
						v-model="fields.model_nickname"
						variant="outlined"
						placeholder="Model"
						item-title="nickname"
						item-value="nickname"
				></v-autocomplete>
			</v-col>
			<v-col cols="6">
				<VueDatePicker
						v-model="fields.dob"
						placeholder="Date"
						auto-apply
						:format="format"
				/>
			</v-col>
			<v-col cols="6">
				<v-select
						variant="outlined"
						placeholder="Status"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-select
						variant="outlined"
						placeholder="Type"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-text-field
						variant="outlined"
						placeholder="Duration"
						v-model="fields.payment_details"
						:error-messages="errors.payment_details"
				></v-text-field>
			</v-col>
			<v-col cols="6">
				<v-text-field
						variant="outlined"
						placeholder="Fan name and tag"
						v-model="fields.payment_details"
						:error-messages="errors.payment_details"
				></v-text-field>
			</v-col>
			<v-col cols="6">
				<v-text-field
						variant="outlined"
						placeholder="Send for, $"
						v-model="fields.payment_details"
						:error-messages="errors.payment_details"
				></v-text-field>
			</v-col>
			<v-col cols="6">
				<v-text-field
						variant="outlined"
						placeholder="Full price, $"
						v-model="fields.payment_details"
						:error-messages="errors.payment_details"
				></v-text-field>
			</v-col>
			<v-col cols="12">
				<v-textarea
						v-model="fields.description"
						variant="outlined"
						rows="5"
						placeholder="Description"
						no-resize
						:error-messages="errors.text"
				></v-textarea>
			</v-col>
		</v-row>
	</v-card>
</template>

<style scoped>

</style>