<script>
  import {useDisplay} from "vuetify";
	import DatabookFiles from "@/components/DatabookFiles.vue";
	import FeedContainer from "@/components/feed/FeedContainer.vue";

  export default {
		components: {FeedContainer, DatabookFiles},
    setup () {
      // Destructure only the keys we want to use
      const { lgAndUp, mdAndDown } = useDisplay();

      return { lgAndUp, mdAndDown }
    },


    name: "DatabookInfo",


    props: {
      info: {},
    },


		data() {
			return {
				tab: 'details',
			}
		},


    created() {
      console.log(this.info)
    }
  }
</script>

<template>
	<div>
		<v-sheet class="mb-6 pa-4 pr-6 rounded-lg">
			<v-row align="center">
				<v-col cols="auto">
					<v-avatar v-if="info.avatar" size="90" class="mt-n6 rounded-lg">
						<v-img cover :src="info.avatar"></v-img>
					</v-avatar>
                    <v-avatar v-else size="90" color="primary">
                        <v-icon size="32">mdi-account</v-icon>
                    </v-avatar>
				</v-col>
				<v-col>
					<div class="text-h6 font-weight-black">{{ info.only_fans_model.nickname }}</div>
					<div class="text-subtitle-1 text-grey-darken-1">{{ info.only_fans_model.category?.name }} {{ info.nationality?.nationality_name }}</div>
				</v-col>
				<v-col cols="auto">
					<v-btn prepend-icon="mdi-pencil" color="primary" variant="outlined" @click="$emit('enableEditMode')">edit</v-btn>
				</v-col>
			</v-row>
		</v-sheet>

		<v-tabs
				v-model="tab"
				align-tabs="start"
				color="primary"
				class="mb-6"
		>
			<v-tab value="details">details</v-tab>
<!--			<v-tab>-->
<!--				calendar-->
<!--			</v-tab>-->
<!--			<v-tab>-->
<!--				notes-->
<!--			</v-tab>-->
			<v-tab value="files">files</v-tab>
			<v-tab value="feed">feed</v-tab>
		</v-tabs>

		<v-row v-if="tab === 'details'">
			<v-col :cols="mdAndDown ? '12' : '4'">
				<v-row>
					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">About</div>
							<div v-if="info.model_name" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Name:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.model_name }}</span>
							</div>
							<div v-if="info.age" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Age:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.age }} years</span>
							</div>
							<div v-if="info.dob" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">DOB:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.dob }}</span>
							</div>
							<div v-if="info.zodiac_sign" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Zodiac sign:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.zodiac_sign }}</span>
							</div>
							<div v-if="info.nationality" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Nationality:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.nationality.nationality_name }}</span>
							</div>
							<div v-if="info.country" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Live:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.country.country_name }}</span>
							</div>
							<div v-if="info.languages.length > 0" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Languages:</span>
								<span v-for="(item, index) in info.languages" :key="index" class="text-subtitle-1 text-grey-darken-1">
											<template v-if="index > 0">, </template>
											{{ item.language_name }}
										</span>
							</div>
							<div v-if="info.another_job" class="mb-3">
								<span class="mr-3 body-1 font-weight-medium">Another job/University:</span><span class="text-subtitle-1 text-grey-darken-1">{{ info.another_job }}</span>
							</div>
							<template v-if="info.contacts.length > 0">
								<div class="text-subtitle-1 font-weight-black mb-3 mt-8">Contact information</div>
								<div class="d-flex justify-start mb-3" v-for="(item, index) in info.contacts" :key="index">
									<v-hover>
										<template v-slot:default="{ isHovering, props }">
											<a v-bind="props" :href="item.contact_url" target="_blank" class="d-flex align-center">
												<img width="24" class="mr-2" :src="item.social.icon" :alt="item.social.name">
												<span class="text-caption text-grey-darken-1" :class="{'text-primary': isHovering}">{{ item.social.name}}</span>
											</a>
										</template>
									</v-hover>
								</div>
							</template>
						</v-card>
					</v-col>
					<v-col cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Team</div>
							<div v-if="info.only_fans_model.team_lead" class="mb-3">
								<div class="text-subtitle-1 font-weight-medium">{{ info.only_fans_model.team_lead.full_name }}</div>
								<div class="text-subtitle-2 font-weight-regular text-grey-darken-1">Teamleader</div>
							</div>
							<div v-if="info.only_fans_model.marketer" class="mb-3">
								<div class="text-subtitle-1 font-weight-medium">{{ info.only_fans_model.marketer.full_name }}</div>
								<div class="text-subtitle-2 font-weight-regular text-grey-darken-1">Marketer</div>
							</div>
							<div v-if="info.only_fans_model.client_manager" class="mb-3">
								<div class="text-subtitle-1 font-weight-medium">{{ info.only_fans_model.client_manager.full_name }}</div>
								<div class="text-subtitle-2 font-weight-regular text-grey-darken-1">Client manager</div>
							</div>
						</v-card>
					</v-col>
					<v-col v-if="info.model_conditions" cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Models present, conditions</div>
							<div class="text-subtitle-1 font-weight-medium">{{ info.model_conditions }}</div>
						</v-card>
					</v-col>
					<v-col v-if="info.platforms.length > 0" cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Platforms</div>
							<div class="d-flex justify-start mb-3" v-for="(item, index) in info.platforms" :key="index">
								<v-hover>
									<template v-slot:default="{ isHovering, props }">
										<a v-bind="props" :href="item.platform_url" target="_blank" class="d-flex align-center">
											<img width="24" class="mr-2" :src="item.social.icon" :alt="item.social.name">
											<span class="text-caption text-grey-darken-1" :class="{'text-primary': isHovering}">{{ item.social.name}}</span>
										</a>
									</template>
								</v-hover>
							</div>
						</v-card>
					</v-col>
					<v-col v-if="info.sub_profiles.length > 0" cols="12">
						<v-card class="pa-6">
							<div class="text-subtitle-1 font-weight-black mb-3">Subprofiles</div>
							<div v-for="(item, index) in info.sub_profiles" :key="index" class="text-subtitle-1 font-weight-medium text-grey-darken-1 mb-3">{{ item.nickname }}</div>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
			<v-col :cols="mdAndDown ? '12' : '8'">
				<v-row class="mb-3">
					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Yes list</div>
							<div v-if="info.yes_list.length > 0" class="ma-n1">
								<v-chip
										v-for="(item, index) in info.yes_list.split(',')"
										:key="index"
										variant="outlined"
										size="large"
										color="teal"
										class="ma-1"
								>{{ item }}</v-chip>
							</div>
							<div v-else>
								Empty list
							</div>
						</v-card>
					</v-col>
					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Stop list</div>
							<div v-if="info.stop_list.length > 0"  class="ma-n1">
								<v-chip
										v-for="(item, index) in info.stop_list.split(',')"
										:key="index"
										variant="outlined"
										size="large"
										color="pink-darken-1"
										class="ma-1"
								>{{ item }}</v-chip>
							</div>
							<div v-else>
								Empty list
							</div>
						</v-card>
					</v-col>
					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Like</div>
							<div v-if="info.like.length > 0"  class="ma-n1">
								<v-chip
										v-for="(item, index) in info.like.split(',')"
										:key="index"
										variant="outlined"
										size="large"
										color="teal"
										class="ma-1"
								>{{ item }}</v-chip>
							</div>
							<div v-else>
								Empty list
							</div>
						</v-card>
					</v-col>
					<v-col :cols="mdAndDown ? '12' : '6'">
						<v-card class="pa-6 fill-height">
							<div class="text-subtitle-1 font-weight-black mb-3">Dont like</div>
							<div v-if="info.dont_like.length > 0" class="ma-n1">
								<v-chip
										v-for="(item, index) in info.dont_like.split(',')"
										:key="index"
										variant="outlined"
										size="large"
										color="pink-darken-1"
										class="ma-1"
								>{{ item }}</v-chip>
							</div>
							<div v-else>
								Empty list
							</div>
						</v-card>
					</v-col>
					<v-col v-if="info.hobby" cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Hobby</div>
							<div class="text-subtitle-1 text-grey-darken-2 font-weight-medium">{{ info.hobby }}</div>
						</v-card>
					</v-col>
					<v-col v-if="info.dreams" cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Dreams</div>
							<div class="text-subtitle-1 text-grey-darken-2 font-weight-medium">{{ info.dreams }}</div>
						</v-card>
					</v-col>
					<v-col v-if="info.goals" cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Goals</div>
							<div class="text-subtitle-1 text-grey-darken-2 font-weight-medium">{{ info.goals }}</div>
						</v-card>
					</v-col>
					<v-col v-if="info.other" cols="12">
						<v-card class="pa-6">
							<div class="text-h6 font-weight-black mb-3">Other information</div>
							<div class="text-subtitle-1 text-grey-darken-2 font-weight-medium">{{ info.other }}</div>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<DatabookFiles v-if="tab === 'files'" :id="info.id" />

		<FeedContainer v-if="tab === 'feed'" :model-info="this.info.id"/>
	</div>
</template>

<style scoped>

</style>