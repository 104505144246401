<script>
import {mapState} from "vuex";
import apiFinance from "@/api/finance";

export default {
	name: "PayoutConfirmMassDialog",


	props: {
		items: {},
		totalAmount: {}
	},


	data() {
		return {
			formLoading: false,
			fields: {},
			addresses: [],
			addressesLoading: false,
			addressesLoaded: false,
			errors: {}
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
		})
	},


	created() {
		this.getPayoutAddresses();
		this.setFields();
	},


	watch: {},


	methods: {

		setFields() {
			this.fields.financier = structuredClone(this.userInfo.full_name);
			this.fields.payout_address = null
		},

		async getPayoutAddresses() {
			this.addressesLoading = true;
			this.addressesLoaded = false;
			this.addresses = await apiFinance.getPayoutAddresses()
			this.addressesLoading = false;
			this.addressesLoaded = true;
		},

		async handlePayoutProvide() {
			this.formLoading = true;
			try {
				const data = {
					finance_review_result: 'accept',
					payout_reviews: this.items,
					payout_address: this.fields.payout_address,
				};
				const response = await apiFinance.massProvide(data);
				localStorage.last_address = this.fields.payout_address;
				this.$emit('accept', response);
				this.$emit('close');
			} catch (error) {
				console.log(error);
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		}

	}
}
</script>

<template>
	<v-card class="pa-6">
		<v-form>
			<div class="mb-4 text-body-1">Payout info</div>

			<v-row class="mb-6 text-body-2">
				<v-col>
					<div class="mb-2"><span class="py-2 text-grey-darken-1">Items:</span> <span class="py-2 text-right font-weight-medium">{{ this.items.length }}</span></div>
				</v-col>
				<v-col>
					<div class="mb-2"><span class="py-2 text-grey-darken-1">Total amount:</span> <span class="py-2 text-right font-weight-medium">{{ totalAmount }}</span></div>
				</v-col>
			</v-row>

			<div class="mb-6">
				<div class="text-body-1 mb-2">Financier</div>
				<v-text-field disabled readonly placeholder="Financier" v-model="fields.financier" variant="outlined"></v-text-field>
			</div>

			<div class="mb-6">
				<div class="text-body-1 mb-2">Payout address</div>
				<v-combobox
						variant="outlined"
						item-title="address_name"
						item-value="address_name"
						placeholder="Payout address"
						:loading="!addressesLoaded"
						:disabled="!addressesLoaded"
						:items="addresses"
						v-model="fields.payout_address"
						:return-object="false"
						:error-messages="errors.payout_address"
				></v-combobox>
			</div>

			<v-row justify="end" no-gutters>
				<v-btn
						variant="text"
						@click="$emit('close')"
						text="Cancel"
				></v-btn>
				<v-btn
						:disabled="formLoading || addressesLoading"
						:loading="formLoading || addressesLoading"
						class="ml-2" color="primary"
						@click="handlePayoutProvide"
						text="Confirm"
				></v-btn>
			</v-row>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>