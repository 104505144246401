<script>
import PayoutCard from "@/components/PayoutCard.vue";
import apiFinance from "@/api/finance"
import ScrollingPagination from "@/components/ScrollingPagination.vue";
import {useDisplay} from "vuetify";
import {mapState} from "vuex";
import PayoutEditDialog from "@/components/PayoutEditDialog.vue";
import PayoutConfirmMassDialog from "@/components/PayoutConfirmMassDialog.vue";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "PayoutsPage",


	components: {
		PayoutConfirmMassDialog,
		PayoutEditDialog,
		PayoutCard,
		ScrollingPagination,
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			tab: '',
			search: '',
			date: this.setDefaultDateRange(),
			wsPayment: null,
			pageTitle: 'Payouts',
			payoutEditOpen: false,
			payoutConfirmProvideAllOpen: false,
			page: 1,
			items: [],
			itemsCount: null,
			totalSum: null,
			itemsLoading: false,
			itemsLoaded: false,
			editedItem: {},
			defaultItem: {
				payout_creation_date: null,
				only_fans_model: null,
				amount: null,
				payout_address: null,
			},
			editedIndex: -1,
			pageLoaded: false,
			selectMode: false,
			selectedItems: [],
			totalAmount: 0,
		}
	},


	computed: {
		...mapState ({
			userInfo: state => state.user.info,
			userLoading: state => state.user.userLoading,
			userLoaded: state => state.user.userLoaded,
		}),
	},


	created() {
		if (localStorage.payout_status) {
			this.tab = localStorage.payout_status
		}
		if (localStorage.payouts_date_start && localStorage.payouts_date_end) {
			this.date[0] = new Date(localStorage.payouts_date_start);
			this.date[1] = new Date(localStorage.payouts_date_end);
		}
		if(this.userLoaded) {
			this.connectPaymentsWS();
		}
		this.paginationReset();
		this.getPayouts();
	},


	watch: {
		userLoaded(value) {
			if (value) {
				this.connectPaymentsWS();
			}
		},
		tab() {
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getPayouts();
				localStorage.payout_status = this.tab
			}
		},
		date() {
			this.paginationReset();
			this.getPayouts();
			localStorage.payouts_date_start = this.date[0];
			localStorage.payouts_date_end = this.date[1];
		},
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.paginationReset();
				this.getPayouts()
			}, 400);
		},
	},


	methods: {
		connectPaymentsWS() {
			const thisApp = this;
			const BASE_URL = (process.env.VUE_APP_URL).split('//')[1].split('/')[0];
			if (this.userLoaded) {
				this.wsPayment = new WebSocket(`wss://${BASE_URL}/ws/payout-reviews/`);
				this.wsPayment.onmessage = function(event) {
					let json = JSON.parse(event.data);
					console.log(json);
					if (json.event_type === 'updated') {
						thisApp.handleUpdate(json.data)
					}
					if (json.event_type === 'created') {
						thisApp.handleCreate(json.data)
					}
				}
			}
		},
		closeWebsocket() {
			if (this.wsPayment) {
				this.wsPayment.close(1000, 'работа закончена');
			}
		},
		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), 1);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		paginationReset() {
			this.items = [];
			this.itemsLoaded = false;
			this.page = 1;
		},
		async getPayouts() {
			this.itemsLoading = true;
			this.itemsLoaded = false;
			const data = {
				page: this.page,
				finance_review_result: this.tab,
				search: this.search,
				payout_creation_date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
				payout_creation_date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
			}
			try {
				const response = await apiFinance.getPayoutsReviews(data);
				this.items = this.items.concat(response.results);
				this.itemsCount = response.count;
				this.totalSum = response.total_sum;
				this.page = response.next;
				this.itemsLoading = false;
				this.itemsLoaded = true;
				this.pageLoaded = true;
			} catch (error) {
				console.log(error)
			}
		},
		handleReject(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index !== -1) {
				this.items[index] = response
			}
		},
		handleAccept(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index !== -1) {
				this.items[index] = response
			}
			this.disableSelectMode()
		},
		handleUpdate(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index !== -1) {
				this.items[index] = response
			}
		},
		handleCreate(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index === -1) {
				this.items.unshift(response)
			}
		},
		handleEditClick(info) {
			this.payoutEditOpen = true;
			this.editedIndex = 1;
			this.editedItem = info
		},
		handleCreateClick() {
			this.payoutEditOpen = true;
			this.editedItem = Object.assign({}, this.defaultItem)
		},
		handleCancelEditDialog() {
			this.payoutEditOpen = false;
			this.editedItem = Object.assign({}, this.defaultItem)
			this.editedIndex = -1;
		},
		loadMore() { if (this.page && !this.itemsLoading) { this.getPayouts() } },
		enableSelectMode() { this.selectMode = true; },
		disableSelectMode() { this.selectedItems = []; this.totalAmount = 0; this.selectMode = false; },
		select(info) {
			const index = this.selectedItems.findIndex(item => item === info.id)
			if (index !== -1) {
				this.selectedItems.splice(index, 1);
				this.totalAmount = this.totalAmount - info.amount
			} else {
				this.selectedItems.push(info.id);
				this.totalAmount = this.totalAmount + info.amount
			}
		}
	}
}
</script>

<template>
	<div class="position-relative fill-height">
		<ScrollingPagination class="position-relative fill-height overflow-y-auto" @loadMore="loadMore">
			<div class="page-container pt-4 pb-10">
				<div class="page-wrap">

					<h1 class="text-h4 mb-8">{{ pageTitle }}</h1>

					<div class="mb-6">
						<v-btn
								color="primary"
								prepend-icon="mdi-plus"
								@click="handleCreateClick"
								text="new payout"
						></v-btn>
					</div>

					<v-tabs
							class="mb-6"
							center-active
							color="primary"
							v-model="tab"
					>
						<v-tab value="">All</v-tab>
						<v-tab value="review">To work</v-tab>
						<v-tab value="accept">Provided</v-tab>
						<v-tab value="reject">Decline</v-tab>
					</v-tabs>

					<v-row justify="space-between" align="end" class="mb-6">
						<v-col cols="auto">
							<div class="text-subtitle-2 mb-2">Creation date:</div>
							<VueDatePicker
									class="density-compact clearable-false"
									v-model="date"
									range
									auto-apply
									:format="format"
									:clearable="false"
							/>
						</v-col>
						<v-col cols="auto">
							<div style="max-width: 100%; width: 400px">
								<v-text-field
										v-model="search"
										variant="underlined"
										label="Search"
										append-inner-icon="mdi-magnify"
										density="compact"
										hide-details
								></v-text-field>
							</div>
						</v-col>
					</v-row>

					<template v-if="pageLoaded">
						<v-row no-gutters align="center">
							<v-col>
								<span class="text-subtitle-2 font-weight-bold">Number of payouts: {{ itemsCount }}</span>
								<span class="text-subtitle-2 font-weight-bold ml-4">Total sum: {{ totalSum }}</span>
							</v-col>
							<v-col cols="auto">
								<v-btn prepend-icon="mdi-close" v-if="selectMode" color="primary" @click="disableSelectMode">Cancel select mode</v-btn>
								<v-btn prepend-icon="mdi-checkbox-multiple-marked-outline" v-else color="primary" @click="enableSelectMode">Multiple select</v-btn>
							</v-col>
						</v-row>
						<div class="mt-4">
							<div class="mt-2" v-if="itemsCount > 0">
								<div class="mb-6" v-for="(item, index) in items" :key="index">
									<v-hover>
										<template v-slot:default="{ isHovering, props }">
											<PayoutCard
													v-bind="props"
													:selected="selectedItems.includes(item.id)"
													:select-mode="selectMode"
													:class="[selectMode && item.finance_review_result === 'review' ? 'cursor-pointer' : '']"
													:style="selectMode && isHovering && !(selectedItems.includes(item.id)) && item.finance_review_result === 'review' ? 'background-color: #f1fafe !important' : ''"
													:info="item"
													class="transition"
													@reject="handleReject"
													@accept="handleAccept"
													@update="handleUpdate"
													@edit="handleEditClick"
													@click="selectMode && item.finance_review_result === 'review' ? select(item) : ''"
											></PayoutCard>
										</template>
									</v-hover>
								</div>
							</div>
							<div v-else class="mt-10 text-body-1 text-center">
								<span>...</span><br>
								<span>Нет данных, соответствующих выбранным фильтрам</span>
							</div>
						</div>
					</template>

					<template v-if="itemsLoading">
						<v-row no-gutters align="center" justify="space-between">
							<v-col class="d-flex">
								<v-skeleton-loader height="" width="170" class="bg-transparent" type="text"></v-skeleton-loader>
								<v-skeleton-loader width="140" class="bg-transparent" type="text"></v-skeleton-loader>
							</v-col>
							<v-col cols="auto">
								<v-skeleton-loader width="170" class="bg-transparent" type="text"></v-skeleton-loader>
							</v-col>
						</v-row>
						<div class="mt-3">
							<v-skeleton-loader
									v-for="(item, index) in 3"
									:key="index"
									class="rounded-lg elevation-2 mb-6"
									type="table-heading, list-item-two-line, actions"
							></v-skeleton-loader>
						</div>
					</template>

					<v-dialog v-model="payoutEditOpen" fullscreen persistent transition="slide-x-transition" max-width="1100px" class="right-position">
						<PayoutEditDialog
								@close="handleCancelEditDialog"
								:edited="editedIndex"
								:info="editedItem"
								@update="handleUpdate"
						></PayoutEditDialog>
					</v-dialog>

				</div>
			</div>
		</ScrollingPagination>
		<v-fade-transition>
			<v-sheet
					v-if="selectMode"
					elevation="6"
					rounded="lg"
					max-width="1000"
					width="90%"
					class="px-4 py-2 position-absolute d-flex justify-space-between"
					color="grey-darken-4"
					style="bottom: 28px; left: 50%; transform: translateX(-50%); z-index: 99999999"
			>
				<v-btn density="comfortable" variant="outlined" @click="selectedItems = []">{{ selectedItems.length }} request selected <v-icon class="ml-2">mdi-close</v-icon></v-btn>
				<div>
					<v-btn class="mr-3" variant="outlined" density="comfortable" color="white" @click="disableSelectMode">Cancel</v-btn>
					<v-btn :disabled="selectedItems.length === 0" density="comfortable" color="white" @click="payoutConfirmProvideAllOpen = true">Provide all</v-btn>
				</div>
			</v-sheet>
		</v-fade-transition>
		<v-dialog v-model="payoutConfirmProvideAllOpen" persistent width="600">
			<PayoutConfirmMassDialog
					@close="payoutConfirmProvideAllOpen = false"
					:total-amount="totalAmount"
					:items="selectedItems"
					@accept="handleAccept"
			></PayoutConfirmMassDialog>
		</v-dialog>
	</div>
</template>

<style scoped>

</style>