<template>
	<div class="fill-height d-flex flex-column">

		<div class="flex-1-1 d-flex flex-column position-relative">
			<div class="fill-height w-100 position-absolute" style="overflow-y: scroll">
				<div ref="wrapper" class="page-container pt-4" style="padding-bottom: 80px">
					<div class="page-wrap">
						<h1 class="text-h4 mb-8">Budget</h1>

						<v-row class="mb-2 justify-space-between">
							<v-col cols="auto">
								<v-btn :disabled="!periodLoaded" prepend-icon="mdi-plus" variant="outlined" color="primary" @click="dialogEditDepartment = true">Add department</v-btn>
							</v-col>
<!--							<v-col cols="auto">-->
<!--								<div style="max-width: 100%; width: 400px">-->
<!--									<v-text-field-->
<!--											:disabled="!periodLoaded"-->
<!--											v-model="search"-->
<!--											variant="underlined"-->
<!--											label="Поиск по модели"-->
<!--											append-inner-icon="mdi-magnify"-->
<!--											density="compact"-->
<!--									></v-text-field>-->
<!--								</div>-->
<!--							</v-col>-->
						</v-row>

						<div>

							<div v-if="periodLoaded" class="d-flex" :class="{'w-fit': mdAndDown}">
								<div
										v-for="(item, index) in heads"
										:key="index"
										class="px-4 pt-2 pb-3 text-body-1 font-weight-medium"
										style="width: unset"
										:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
								>{{headName(item)}}</div>
							</div>

							<template v-if="periodsLoaded">

								<template v-if="periods.length > 0">
									<template v-if="periodLoaded">
										<template v-if="items.length > 0">
											<template v-if="filteredItems.length > 0">
												<template v-for="(item, index) in filteredItems" :key="index">
													<v-hover>
														<template v-slot:default="{ isHovering, props }">
															<div
																	v-bind="props"
																	class="d-flex elevation-1 rounded-lg mb-2 cursor-pointer transition"
																	:class="[{'w-fit': mdAndDown}, setItemBgColor(index, isHovering)]"
																	@click="openEditDepartment(item)"
															>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.department + 'px' } : { width: sizes.department + '%' }">{{ item.department.name }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.plan_budget + 'px' } : { width: sizes.plan_budget + '%' }">{{ item.expected_plan }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.plan_wage_fund + 'px' } : { width: sizes.plan_wage_fund + '%' }">{{ item.expected_wage_fund }}</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.fact_budget + 'px' } : { width: sizes.fact_budget + '%' }">
																	<div class="d-flex align-center">
																		<span style="width: 70px" class="text-no-wrap">{{ item.budget_fact }}</span>
																		<div style="width: 80px">
																			<v-progress-linear
																					:model-value="calcValue(item.expected_plan, item.budget_fact)"
																					:color="calcColor(item.expected_plan, item.budget_fact)"
																					rounded
																					height="12"
																			></v-progress-linear>
																		</div>
																		<span class="ml-2 text-no-wrap font-weight-bold" :class="'text-' + calcColor(item.expected_plan, item.budget_fact)">{{calcValue(item.expected_plan, item.budget_fact)}}%</span>
																	</div>
																</div>
																<div class="flex-0-0" :class="tableCellClass" :style="mdAndDown ? { width: sizesMobile.fact_wage_fund + 'px' } : { width: sizes.fact_wage_fund + '%' }">
																	<div class="d-flex align-center">
																		<span style="width: 70px" class="text-no-wrap">{{ item.wage_fund_fact }}</span>
																		<div style="width: 80px">
																			<v-progress-linear
																					:model-value="calcValue(item.expected_wage_fund, item.wage_fund_fact)"
																					:color="calcColor(item.expected_wage_fund, item.wage_fund_fact)"
																					rounded
																					height="12"
																			></v-progress-linear>
																		</div>
																		<span class="ml-2 text-no-wrap font-weight-bold" :class="'text-' + calcColor(item.expected_wage_fund, item.wage_fund_fact)">{{calcValue(item.expected_wage_fund, item.wage_fund_fact)}}%</span>
																	</div>
																</div>
																<div class="flex-0-0 px-4 d-flex justify-end align-center" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }">
																	<v-btn color="grey" density="comfortable" icon="mdi-delete" variant="text" @click.stop="deleteDepartment(item)" ></v-btn>
																</div>
															</div>
														</template>
													</v-hover>
												</template>
											</template>
											<template v-else>
												<div class="mt-10 text-body-1 text-center">
													<span>...</span><br>
													<span>Моделей по заданному запросу не обнаружено</span>
												</div>
											</template>
										</template>
										<template v-else>
											<div class="mt-10 text-body-1 text-center">
												<span>...</span><br>
												<span>Add a department to start filling out the plan</span>
											</div>
										</template>
									</template>
									<v-skeleton-loader v-else type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>
								</template>

								<template v-else>
									<div class="mt-10 text-body-1 text-center">
										<span>...</span><br>
										<span>Create your first monthly plan to get started</span>
									</div>
								</template>

							</template>

						</div>

						<v-dialog v-model="dialogDeleteDepartment" max-width="340px">
							<v-card class="pa-6">
								<div class="text-body-1 mb-2">Remove department from plan</div>
								<div class="text-subtitle-2">Are you sure?</div>
								<v-row justify="end" no-gutters class="mt-10 pa-0">
									<v-btn color="primary" class="mr-2" variant="text" @click="closeDeleteDepartment">Cancel</v-btn>
									<v-btn color="primary" @click="deleteDepartmentConfirm">Confirm</v-btn>
								</v-row>
							</v-card>
						</v-dialog>

						<v-dialog v-model="dialogEditDepartment" max-width="500px">
							<BudgetFinanceDepartmentEditDialog
									:raw-departments="departments"
									:departments-loaded="departmentsLoaded"
									:edited="editedDepartmentIndex"
									:info="editedDepartment"
									:period="period.id"
									:selected="selectedDepartments"
									@close="closeEditDepartment"
									@add="addDepartment"
									@edit="editDepartment"
							/>
						</v-dialog>

						<v-dialog v-model="dialogEditPlan" fullscreen persistent transition="slide-x-transition" max-width="1300px" class="right-position">
							<BudgetFinanceEditDialog
									:raw-departments="departments"
									:departments-loaded="departmentsLoaded"
									:edited="editedPlanIndex"
									:info="editedPlan"
									:periods="periods"
									@close="closeEditPlan"
									@add="addPlan"
									@edit="editPlan"
							/>
						</v-dialog>

					</div>
				</div>
			</div>


			<div v-if="periodLoaded" class="page-container w-100 position-absolute" style="bottom: 0; z-index: 1" :style="`max-width: ${wrapperWidth}px`">
				<div class="d-flex mb-2 transition">
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.department + 'px' } : { width: sizes.department + '%' }"></div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.plan_budget + 'px' } : { width: sizes.plan_budget + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Total budget plan:<br></span> {{ planTotalValues.planBudget }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.plan_wage_fund + 'px' } : { width: sizes.plan_wage_fund + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Total wage fund plan:<br></span> {{ planTotalValues.planWageFund }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.fact_budget + 'px' } : { width: sizes.fact_budget + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Total budget fact:<br></span> {{ planTotalValues.factBudget }}</div>
					</div>
					<div class="pr-2 flex-0-0" :style="mdAndDown ? { width: sizesMobile.fact_wage_fund + 'px' } : { width: sizes.fact_wage_fund + '%' }">
						<div class="bg-grey-lighten-2 elevation-1 rounded-lg" :class="tableCellClass"><span class="font-weight-bold">Total wage fund fact:<br></span> {{ planTotalValues.factWageFund }}</div>
					</div>
					<div class="flex-0-0" :style="mdAndDown ? { width: sizesMobile.action + 'px' } : { width: sizes.action + '%' }"></div>
				</div>
			</div>

		</div>

		<div class="flex-0-0">
			<div class="d-flex align-center bg-white">
				<v-skeleton-loader v-if="!periodsLoaded" type="avatar@2, chip@4" class="bg-transparent w-100"></v-skeleton-loader>
				<template v-else>
					<div class="d-flex pa-2 pr-6">
						<v-btn :disabled="!periods.length > 0" class="mr-2" icon="mdi-pencil" variant="text" color="primary" @click="openEditPlan"></v-btn>
						<v-btn icon="mdi-plus" variant="text" color="primary" @click="dialogEditPlan = true"></v-btn>
					</div>
					<template v-if="periods.length > 0">
						<v-tabs
								center-active
								color="primary"
								v-model="tab"
						>
							<v-tab v-for="(item, index) in periods" :key="index" :value="item.id">{{ item.name }}</v-tab>
						</v-tabs>
					</template>
					<template v-else>
						The list of plans is empty. Add a new monthly plan.
					</template>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
import apiBudgetFinance from '@/api/budget-finance';
import apiFinanceCashflow from '@/api/finance-cashflow';
import BudgetFinanceDepartmentEditDialog from '@/components/BudgetFinanceDepartmentEditDialog.vue';
import BudgetFinanceEditDialog from '@/components/BudgetFinanceEditDialog.vue';
import {useDisplay} from "vuetify";

export default ({
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'BudgetFinancierPage',


	components: {
		BudgetFinanceDepartmentEditDialog,
		BudgetFinanceEditDialog,
	},


	data() {
		return {
			heads: ['department', 'plan_budget', 'plan_wage_fund', 'fact_budget', 'fact_wage_fund', 'action'],
			sizes: {
				department: 100/1000*170,
				plan_budget: 100/1000*160,
				plan_wage_fund: 100/1000*160,
				fact_budget: 100/1000*230,
				fact_wage_fund: 100/1000*230,
				action: 100/1000*50,
			},
			sizesMobile: {
				department: '200',
				plan_budget: '200',
				plan_wage_fund: '200',
				fact_budget: '200',
				fact_wage_fund: '200',
				action: '100',
			},
			tableCellClass: 'px-4 py-3 text-no-wrap overflow-hidden text-overflow-ellipsis text-body-2',
			dialogDeleteDepartment: false,
			dialogEditDepartment: false,
			dialogEditPlan: false,
			search: '',
			searchItem: [],
			tab: '',
			editedDepartmentIndex: -1,
			editedDepartment: '',
			defaultDepartment: '',
			periods: [],
			periodsLoading: false,
			periodsLoaded: false,
			departments: [],
			departmentsLoaded: false,
			editedPlanIndex: -1,
			editedPlan: '',
			defaultPlan: '',
			period: null,
			periodLoaded: false,
			items: [],
			planTotalValues: {},
			wrapperWidth: 0,
		}
	},


	computed: {
		filteredItems() {
			return this.searchItem.filter((item) => {
				if (item.department.name.toLowerCase().match(this.search)) return true
			});
		},
		selectedDepartments() {
			let result = [];
			this.items.forEach(item => {
				result.push(item.department.name);
			})
			return result
		}
	},


	created() {
		this.getPeriods();
		this.getDepartments();
	},


	mounted() {
		this.wrapperWidth = this.$refs.wrapper.getBoundingClientRect().width
	},


	watch: {
		tab() {
			this.getPeriod();
		}
	},


	methods: {
		async getDepartments() {
			try {
				this.departmentsLoaded = false;
				this.departments = await apiFinanceCashflow.getDepartments();
				this.departmentsLoaded = true
			} catch (error) {
				console.log('departments loading error: ', error)
			}
		},
		async getPeriods() {
			try {
				this.periodsLoaded = false;
				this.periods = await apiBudgetFinance.getPeriods();
				this.periodsLoaded = true
			} catch (error) {
				console.log('periods loading error: ', error)
			}
		},

		async getPeriod() {
			try {
				this.periodLoaded = false;
				this.period = await apiBudgetFinance.getPeriod(this.tab);
				const list = this.period.budgets;
				list.forEach((item) => {
					if (item.department === null) {
						item.department = { name: '-' }
					}
				})
				this.items = list;
				this.sortItems();
				this.searchItem = this.items;
				this.periodLoaded = true;
				this.calcTotal();
			} catch (error) {
				console.log('selected period loading error: ', error)
			}
		},

		headName(value) {
			if (value === 'department') return 'Department';
			if (value === 'plan_budget') return 'Budget plan';
			if (value === 'plan_wage_fund') return 'Wage fund plan';
			if (value === 'fact_budget') return 'Budget fact';
			if (value === 'fact_wage_fund') return 'Wage fund fact';
		},

		setItemBgColor(index, hover) {
			if (hover) {
				return 'bg-cyan-lighten-5'
			} else if (index % 2) {
				return 'bg-grey-lighten-4'
			} else {
				return 'bg-white'
			}
		},

		calcValue(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			return Math.round(value2 / value1 * 100);
		},

		calcColor(plan_value, fact_value) {
			const value1 = String(plan_value).replaceAll(' ', '') ;
			const value2 = String(fact_value).replaceAll(' ', '') ;
			const result = Math.round(value2 / value1 * 100);
			if (result > 100) {
				return 'deep-orange-darken-1'
			} else if (result <= 100 && result > 70) {
				return 'amber-darken-2'
			} else if (result <=70) {
				return 'cyan-darken-3'
			}
		},

		openEditDepartment(item) {
			this.editedDepartmentIndex = this.items.indexOf(item);
			this.editedDepartment = Object.assign({}, item);
			this.dialogEditDepartment = true
		},

		closeEditDepartment() {
			this.dialogEditDepartment = false;
			this.$nextTick(() => {
				this.editedDepartment = Object.assign({}, this.deleteDepartment);
				this.editedDepartmentIndex = -1
			})
		},

		deleteDepartment(item) {
			this.editedDepartmentIndex = this.items.indexOf(item);
			this.editedDepartment = Object.assign({}, item);
			this.dialogDeleteDepartment = true
		},

		closeDeleteDepartment() {
			this.dialogDeleteDepartment = false;
			this.$nextTick(() => {
				this.editedDepartment = Object.assign({}, this.defaultDepartment);
				this.editedDepartmentIndex = -1
			})
		},

		async deleteDepartmentConfirm() {
			try {
				this.deleteLoading = true;
				await apiBudgetFinance.deletePlanDepartment(this.editedDepartment.id);
				this.closeDeleteDepartment();
				const index = this.items.findIndex(item => item.id === this.editedDepartment.id);
				if (index !== -1) {
					this.items.splice(index, 1)
				}
			} catch (error) {
				console.log('delete department error: ', error)
			} finally {
				this.deleteLoading = false;
			}
		},

		addDepartment(data) {
			this.items.push(data);
			this.calcTotal();
			this.sortItems();
		},

		editDepartment(data) {
			const index = this.items.findIndex(item => item.id === data.id);
			this.items[index] = data;
			this.calcTotal();
		},

		openEditPlan() {
			this.editedPlanIndex = 1;
			this.editedPlan = Object.assign({}, this.period);
			this.dialogEditPlan = true
		},

		closeEditPlan() {
			this.dialogEditPlan = false;
			this.$nextTick(() => {
				this.editedPlan = Object.assign({}, this.defaultPlan);
				this.editedPlanIndex = -1
			})
		},

		addPlan(data) {
			const item = {
				end_date: data.end_date,
				id: data.id,
				name: data.name,
				start_date: data.start_date,
			}
			this.periods.push(item);
			this.periods.sort((item1, item2) => item1['start_date'] > item2['start_date'] ? 1 : -1).reverse();
		},

		editPlan(data) {
			const index = this.periods.findIndex(item => item.id === data.id);
			this.periods[index].name = data.name;
			this.periods[index].start_date = data.start_date;
			this.periods[index].end_date = data.end_date;
			this.period = data;
			this.items = data.budgets;
			this.searchItem = this.items;
			this.calcTotal();
			this.sortItems();
		},

		calcTotal() {
			const departmentList = Array.from(new Set(this.filteredItems.map(item => item.department.name )));
			let index = departmentList.indexOf('-');
			if (index !== -1) {
				departmentList.splice(index, 1);
			}
			const planBudgetList = this.filteredItems.map(item => item.expected_plan);
			const planWageFundList = this.filteredItems.map(item => item.expected_wage_fund);
			const factBudgetList = this.filteredItems.map(item => item.budget_fact);
			const factWageFundList = this.filteredItems.map(item => item.wage_fund_fact);
			console.log('departmentList: ', departmentList);
			console.log('planBudgetList: ', planBudgetList);
			this.planTotalValues.departmentsCount = departmentList.length;
			this.planTotalValues.planBudget = planBudgetList.reduce((acc, num) => acc + num, 0).toFixed(2);
			this.planTotalValues.planWageFund = planWageFundList.reduce((acc, num) => acc + num, 0).toFixed(2);
			this.planTotalValues.factBudget = factBudgetList.reduce((acc, num) => acc + num, 0).toFixed(2);
			this.planTotalValues.factWageFund = factWageFundList.reduce((acc, num) => acc + num, 0).toFixed(2);
		},

		sortItems() {
			this.items.sort((a, b) => (a.department.name).toLowerCase() > (b.department.name).toLowerCase() ? 1 : -1)
		}
	}
})
</script>

<style scoped>

</style>