<template>
	<v-row>
		<v-col>
			<v-sheet class="d-flex align-center px-5 py-3 elevation-1 rounded-lg fill-height">
				<v-avatar color="deep-orange-lighten-2" size="48">
					<v-icon color="white" icon="mdi-account-multiple-outline"></v-icon>
				</v-avatar>
				<div v-if="!loaded" class="d-flex justify-center flex-grow-1">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</div>
				<div v-else class="pl-4">
					<div class="text-body-1 font-weight-medium">Информация</div>
					<table>
						<tbody>
						<tr>
							<td>Смен:</td>
							<td class="pl-3">{{ statInfo.shifts }}</td>
						</tr>
						<tr>
							<td>Моделей:</td>
							<td class="pl-3">{{ statInfo.models }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</v-sheet>
		</v-col>
		<v-col>
			<v-sheet class="d-flex align-center px-5 py-3  elevation-1 rounded-lg fill-height">
				<v-avatar color="green" size="48">
					<v-icon color="white" icon="mdi-chat-processing-outline"></v-icon>
				</v-avatar>
				<div v-if="!loaded" class="d-flex justify-center flex-grow-1">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</div>
				<div v-else class="pl-4">
					<div class="text-body-1 font-weight-medium">Чаты</div>
					<table>
						<tbody>
						<tr>
							<td>Всего:</td>
							<td class="pl-3">{{ totalUniqueChats }}</td>
						</tr>
						<tr>
							<td>Среднее:</td>
							<td class="pl-3">{{ averageTotalUniqueChats }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</v-sheet>
		</v-col>
		<v-col>
			<v-sheet class="d-flex align-center px-5 py-3  elevation-1 rounded-lg fill-height">
				<v-avatar color="orange-lighten-2" size="48">
					<v-icon color="white" icon="mdi-message-arrow-right-outline"></v-icon>
				</v-avatar>
				<div v-if="!loaded" class="d-flex justify-center flex-grow-1">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</div>
				<div v-else class="pl-4">
					<div class="text-body-1 font-weight-medium">Сообщения</div>
					<table>
						<tbody>
						<tr>
							<td>Всего:</td>
							<td class="pl-3">{{ totalOutgoingMessages }}</td>
						</tr>
						<tr>
							<td>Среднее:</td>
							<td class="pl-3">{{ averageTotalOutgoingMessages }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</v-sheet>
		</v-col>
		<v-col>
			<v-sheet class="d-flex align-center px-5 py-3  elevation-1 rounded-lg fill-height">
				<v-avatar color="blue-accent-1" size="48">
					<v-icon color="white" icon="mdi-speedometer"></v-icon>
				</v-avatar>
				<div v-if="!loaded" class="d-flex justify-center flex-grow-1">
					<v-progress-circular color="primary" indeterminate></v-progress-circular>
				</div>
				<div v-else class="pl-4">
					<div class="text-body-1 font-weight-medium">Скорость ответа</div>
					<table>
						<tbody>
						<tr>
							<td>Среднее:</td>
							<td class="pl-3">{{ averageSpeedAnswer }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: 'TotalStatPanel',


    data() {
      return {
        loading: false,
        loaded: false,
        statInfo: {
          shifts: '0',
          models: '0',
        },
        totalUniqueChats: '0',
        averageTotalUniqueChats: '0',
        totalOutgoingMessages: '0',
        averageTotalOutgoingMessages: '0',
        averageSpeedAnswer: '-',
      }
    },


    computed: {
      ...mapState({
        activeLead: state => state.user.activeLead,
        shifts: state => state.shifts.shifts,
        shiftsLoading: state => state.shifts.shiftsLoading,
        shiftsLoaded: state => state.shifts.shiftsLoaded,
      }),
    },


    created() {
      if (this.shiftsLoaded) {
        this.setStats();
      }
    },


    watch: {
      shiftsLoaded(value) {
        if (value) {
          this.setStats();
        }
      },
      activeLead() {
        this.setStats();
      },
      shifts: {
        handler() {
          this.setStats();
        },
        deep: true,
      },
    },


    methods: {
      setStats() {
        this.loaded = false;
        this.loading = true;
        let shiftsByLead = [];
        if (this.activeLead === '0') {
          shiftsByLead = this.shifts.slice()
        } else {
          shiftsByLead = this.shifts.filter((item) => item.team_lead.id === this.activeLead)
        }

				let correctShifts = [];
				shiftsByLead.forEach((el) => {
					if (el.shift_info !== null) {
						correctShifts.push(el);
					}
				});

				shiftsByLead = structuredClone(correctShifts);
				shiftsByLead = shiftsByLead.filter((item) => item.status === 'progress');

				let models = [];
				shiftsByLead.forEach((el) => {
					el.shift_info.models.forEach((model) => {
						if (!model.ended) {
							models.push(model)
						}
					})
				});
				this.statInfo.shifts = shiftsByLead.length;
				this.statInfo.models = models.length;

				if (shiftsByLead.length > 0) {
					const totalUniqueChatsList = shiftsByLead.map(item => item.shift_info.total_statistic.total_unique_chats);
					const totalOutgoingMessagesList = shiftsByLead.map(item => item.shift_info.total_statistic.total_outgoing_messages);
					const averageSpeedAnswerList = shiftsByLead.map(item => item.shift_info.total_statistic.average_speed_answer);
					this.totalUniqueChats = totalUniqueChatsList.reduce((acc, number) => acc + number);
					this.averageTotalUniqueChats = Math.round(this.totalUniqueChats / shiftsByLead.length);
					this.totalOutgoingMessages = totalOutgoingMessagesList.reduce((acc, number) => acc + number);
					this.averageTotalOutgoingMessages = Math.round(this.totalOutgoingMessages / shiftsByLead.length);

					let unrecordedValuesCounter = 0;
					const convertedAverageSpeedAnswerList = [];
					averageSpeedAnswerList.forEach((item) => {
						if (item === '-') {
							unrecordedValuesCounter++;
						} else {
							const itemElements = item.split(':');
							const hours = Number(itemElements[0]);
							const minutes = Number(itemElements[1]);
							const seconds = Number(itemElements[2]);
							const convertedValue = hours * 3600 + minutes * 60 + seconds;
							if (convertedValue === 0) {
								unrecordedValuesCounter++;
							} else {
								convertedAverageSpeedAnswerList.push(convertedValue);
							}
						}
					});
					if (convertedAverageSpeedAnswerList.length > 0) {
						const averageSum = Math.round(convertedAverageSpeedAnswerList.reduce((acc, number) => acc + number) / (shiftsByLead.length - unrecordedValuesCounter));
						const hours = Math.floor(averageSum / 3600);
						const minutes = Math.floor(averageSum / 60) - (hours * 60);
						const seconds = averageSum % 60;
						this.averageSpeedAnswer = [
							hours.toString().padStart(2, '0'),
							minutes.toString().padStart(2, '0'),
							seconds.toString().padStart(2, '0')
						].join(':');
					} else {
						this.averageSpeedAnswer = '-';
					}
				} else {
					this.totalUniqueChats = '0';
					this.averageTotalUniqueChats = '0';
					this.totalOutgoingMessages = '0';
					this.averageTotalOutgoingMessages = '0';
					this.averageSpeedAnswer = '-';
				}

				this.loading = false;
				this.loaded = true;
      }
    },
  }
</script>

<style scoped>

</style>