<script>
import FeedCard from "@/components/feed/FeedCard.vue";

export default {
	name: "FeedList",


	components: {
		FeedCard
	},


	props: {
		items: {},
		itemsLoading: {},
		itemsLoaded: {}
	},


	methods: {
		del(id) {
			this.$emit('delete', id)
		}
	}
}
</script>

<template>
	<TransitionGroup  name="list" tag="div">
		<div v-for="(item, index) in items" :key="item.id" :class="index < items.length - 1 ? 'mb-3' : ''">
			<FeedCard
					:info="item"
					@delete="del"
			/>
		</div>
	</TransitionGroup >
</template>

<style>
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
</style>