<template>
	<v-app>
		<TheSidebarLeft v-model="drawer"/>
		<TheHeader :show="drawer" @toggle-sidebar="drawer = !drawer" @logout="closeWebsocket"/>
		<v-main class="fill-height">
			<RouterView/>
		</v-main>
		<v-sonner
				position="bottom-right"
				expand
				:visible-toasts="9"
		>
			<template #text="data">
				{{data}}
				This is a subtitle
			</template>
		</v-sonner>
	</v-app>
</template>

<script>
  import { VSonner } from "vuetify-sonner"
  import {mapState} from 'vuex';
  import TheSidebarLeft from '@/components/TheSidebarLeft';
  import TheHeader from '@/components/TheHeader.vue';
  import {
    ADD_SHIFT,
    START_SHIFT,
    END_SHIFT,
    REMOVE_SHIFT,
    UPDATE_SHIFT,
  } from '@/store/modules/shifts/constants';

  export default {
    name: 'AppMain',


    components: {
      TheHeader,
      TheSidebarLeft,
			VSonner
    },


    data() {
      return {
        drawer: true,
				ws: null
      }
    },


    computed: {
      ...mapState ({
        userInfo: state => state.user.info,
        userLoading: state => state.user.userLoading,
        userLoaded: state => state.user.userLoaded,
        operators: state => state.operators.operators,
        operatorsLoaded: state => state.operators.operatorsLoaded,
      }),
    },


    created() {
      if (this.$vuetify.display.mdAndDown) {
        this.drawer = false
      }
      this.setStorageData();
      this.getUserData();
      this.getShifts();
      this.getShiftsHistoryByPage();
			if (this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'operator' || this.userInfo.role === 'superuser') {
				this.connectWS();
			}
    },


    watch: {
      userLoaded(value) {
        if (value) {
					if (this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'operator' || this.userInfo.role === 'superuser') {
						this.connectWS();
					}
          this.getShifts();
          this.getShiftsHistoryByPage();
        }
      },
      operators: {
        handler() {
          if (this.operatorsLoaded && (this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'operator')) {
            this.getMyGroupData()
          }
        },
        deep: true,
      }
    },


    methods: {
      setStorageData() {
        const authenticated = {
          token: localStorage.getItem('access_token'),
          authenticated: localStorage.getItem('authenticated'),
        };
        this.$store.dispatch('user/setStorageData', authenticated)
      },

      async getUserData() {
        await this.$store.dispatch('user/getUserData');
        if (this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator') {
          this.getMyGroupData()
					this.getCommonListModels();
        }
        if (this.userInfo.role === 'superuser') {
          this.getTeamLeads();
					this.getCommonListModels()
					this.getMyGroupData()
        }
				if (this.userInfo.role === 'hof' || this.userInfo.role === 'financier') {
					this.getCommonListModels()
				}
				if (this.userInfo.role === 'hom'|| this.userInfo.role === 'marketer') {
					this.getCommonListModels()
				}
				if (this.userInfo.role === 'hobd'|| this.userInfo.role === 'business_dev') {
					this.getCommonListModels()
				}
				if (this.userInfo.role === 'smm' || this.userInfo.role === 'smm_of') {
					this.getCommonListModels()
				}
				if (this.userInfo.role === 'hocm' || this.userInfo.role === 'client_manager') {
					this.getCommonListModels();
					this.getManagers()
				}
				if (this.userInfo.role === 'spectator') {
					this.getTeamLeads();
					this.getCommonListModels()
					this.getMyGroupData()
				}
      },

      getMyGroupData() {
        this.$store.dispatch('user/getMyGroupData')
      },

			getCommonListModels() {
				this.$store.dispatch('user/getCommonListModels')
			},

      getTeamLeads() {
        this.$store.dispatch('user/getTeamLeads')
      },

      getShifts() {
        if (this.userInfo.role && (this.userInfo.role === 'superuser' || this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'operator' || this.userInfo.role === 'spectator')) {
          this.$store.dispatch('shifts/getShifts')
        }
      },

      getShiftsHistoryByPage() {
        if (this.userInfo.role && (this.userInfo.role === 'team_lead' || this.userInfo.role === 'senior_operator' || this.userInfo.role === 'operator')) {
          this.$store.dispatch('shifts/getShiftsHistory', ( 1 ))
        }
      },

			getManagers() {
				this.$store.dispatch('managers/getManagers')
			},

      connectWS() {
        const thisApp = this;
				const BASE_URL = (process.env.VUE_APP_URL).split('//')[1].split('/')[0];
        if (this.userLoaded) {
          this.ws = new WebSocket(`wss://${BASE_URL}/ws/shifts_events/`);
          this.ws.onmessage = function(event) {
            let json = JSON.parse(event.data);
            console.log(json);
            if (json.event_type === 'created') {
              thisApp.$store.commit(`shifts/${ADD_SHIFT}`, json.shift_data);
            }
            if (json.event_type === 'started') {
              thisApp.$store.commit(`shifts/${START_SHIFT}`, json.shift_data);
            }
            if (json.event_type === 'ended') {
              thisApp.$store.commit(`shifts/${END_SHIFT}`, {id: json.shift_id, response: json.shift_data});
            }
            if (json.event_type === 'deleted') {
              thisApp.$store.commit(`shifts/${REMOVE_SHIFT}`, json.shift_id);
            }
            if (json.event_type === 'updated') {
              thisApp.$store.commit(`shifts/${UPDATE_SHIFT}`, json.shift_data);
            }
          }
        }
      },

			closeWebsocket() {
				if (this.ws) {
					this.ws.close(1000, 'работа закончена');
				}
			}

    }
  }
</script>

<style scoped>

</style>