<script>
import {mapState} from "vuex";
import FeedList from "@/components/feed/FeedList.vue";

export default {
	name: "CustomRequestDetailDialog",


	components: {
		FeedList
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			fields: {},
			tabs: 'comments'
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},
}
</script>

<template>
	<v-card class="pa-6">
		<div class="text-body-2 text-grey mb-6">Custom id: 43hr56</div>
		<v-row>
			<v-col cols="7">
				<v-row>
					<v-col cols="12">
						<div class="text-subtitle-1 mb-4">Description</div>
						<v-textarea variant="outlined" no-resize></v-textarea>
					</v-col>
					<v-col cols="12">
						<div class="text-subtitle-1 mb-4">CM control</div>
						<v-row>
							<v-col cols="4">
								<div class="text-subtitle-2 font-weight-light mb-2">Model date</div>
								<VueDatePicker
										class="density-compact"
										v-model="fields.dob"
										placeholder="Date"
										auto-apply
										:format="format"
								/>
							</v-col>
							<v-col cols="4">
								<div class="text-subtitle-2 font-weight-light mb-2">Model finish</div>
								<VueDatePicker
										class="density-compact"
										v-model="fields.dob"
										placeholder="Date"
										auto-apply
										:format="format"
								/>
							</v-col>
							<v-col cols="4">
								<div class="text-subtitle-2 font-weight-light mb-2">Custom upload</div>
								<VueDatePicker
										class="density-compact"
										v-model="fields.dob"
										placeholder="Date"
										auto-apply
										:format="format"
								/>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12">
						<div class="text-subtitle-1 mb-4">Activity</div>
						<div class="d-flex justify-start">
							<v-tabs
									class="mb-8"
									v-model="tabs"
									color="primary"
									align-tabs="center"
									fixed-tabs
							>
								<v-tab value="comments">Comments</v-tab>
							</v-tabs>
						</div>
						<v-textarea variant="outlined" no-resize></v-textarea>
						<div class="d-flex justify-end">
							<v-btn width="130" color="primary">Post</v-btn>
						</div>
						<FeedList />
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="5">
				<v-sheet rounded="lg" border class="px-6 py-4">
					<div class="text-subtitle-1 mb-2">Request info</div>
					<div class="d-flex mb-6">
						<v-icon size="20" color="red-accent-2">mdi-alert-circle</v-icon>
						<span class="pl-2 text-body-2">Deadline: 16.10.2024</span>
					</div>
					<v-row>
						<v-col cols="12">
							<v-autocomplete
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									v-model="fields.model_nickname"
									variant="outlined"
									placeholder="Model"
									item-title="nickname"
									item-value="nickname"
									hide-details
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<VueDatePicker
									v-model="fields.dob"
									placeholder="Date"
									auto-apply
									:format="format"
							/>
						</v-col>
						<v-col cols="12">
							<v-select
									variant="outlined"
									placeholder="Status"
									hide-details
							></v-select>
						</v-col>
						<v-col cols="12">
							<v-select
									variant="outlined"
									placeholder="Status"
									hide-details
							></v-select>
						</v-col>
						<v-col cols="12">
							<v-text-field
									variant="outlined"
									placeholder="Duration"
									hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
									variant="outlined"
									placeholder="Fan name and tag"
									hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
									variant="outlined"
									placeholder="Send for"
									prepend-inner-icon="mdi-currency-usd"
									hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
									variant="outlined"
									placeholder="Full price"
									prepend-inner-icon="mdi-currency-usd"
									hide-details
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="d-flex flex-column align-end">
								<div class="d-flex align-center">
									<v-checkbox color="primary" density="compact" label="Send to fan" hide-details></v-checkbox>
								</div>
								<div class="text-body-2 text-grey">
									<div class="text-right"><v-icon size="20" color="primary">mdi-check-circle</v-icon><span class="pl-2">Send to fan by Александр Сергеев</span></div>
									<div class="text-right">16.10.2024 15:54</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
	</v-card>
</template>

<style scoped>

</style>