<script>
import apiManagers from "@/api/managers";

export default {
	name: "FeedCard",


	props: {
		info: {},
	},


	data: () => ({
		dialogDeleteConfirm: false,
		delLoading: false,
		delLoaded: false,
	}),


	methods: {
		async del() {
			try {
				this.delLoading = true;
				this.delLoaded = false;
				await apiManagers.delFeed(this.info.id);
				this.$emit('delete', this.info.id)
				this.delLoading = false;
				this.delLoaded = true;
				this.dialogDeleteConfirm = false;
			} catch (error) {
				console.log(error)
			}
		}
	}
}
</script>

<template>
	<v-card rounded="lg" class="pa-4" elevation="0">
		<v-row no-gutters justify="start" align="center" class="mb-4">
			<v-col>
				<div class="d-flex align-center flex-wrap">
					<v-chip class="custom-chip" size="x-large" variant="outlined" color="grey-lighten-3">
						<span class="text-subtitle-2 font-weight-bold text-black">{{ info.user.full_name }}</span>
					</v-chip>
					<template v-for="(item, index) in info.models_infos" :key="index">
						<div class="separator"></div>
						<v-chip variant="flat" size="x-large" color="grey-lighten-3">
							<v-avatar size="24">
								<v-img cover v-if="item.avatar" :src="item.avatar"></v-img>
								<v-icon v-else size="24">mdi-account</v-icon>
							</v-avatar>
							<span class="text-subtitle-2 font-weight-bold text-black pl-2">{{ item.only_fans_model.nickname }}</span>
						</v-chip>
					</template>
				</div>
			</v-col>
			<v-col cols="auto">
				<v-tooltip location="top" text="Created at">
					<template v-slot:activator="{ props }">
						<div v-bind="props" class="text-subtitle-2 text-grey d-flex align-center">
							<v-icon class="mr-1">mdi-history</v-icon><span>{{ new Date(this.info.created_at).toLocaleDateString('ru-RU', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}) }}</span>
						</div>
					</template>
				</v-tooltip>
			</v-col>
		</v-row>
		<div class="text-body-1 text-grey-darken-3">{{ info.text }}</div>
		<div class="d-flex justify-end">
			<v-btn variant="text" icon="mdi-delete" @click="dialogDeleteConfirm = true"></v-btn>
		</div>

		<v-dialog v-model="dialogDeleteConfirm" max-width="340px" :disabled="delLoading">
			<v-card class="pa-6">
				<div class="text-body-1 mb-2">Delete live feed item</div>
				<div class="text-subtitle-2">Are you sure?</div>
				<v-row justify="end" no-gutters class="mt-10 pa-0">
					<v-btn color="primary" class="mr-2" variant="text" @click="dialogDeleteConfirm = false">Cancel</v-btn>
					<v-btn color="primary" @click="del" :loading="delLoading">Confirm</v-btn>
				</v-row>
			</v-card>
		</v-dialog>

	</v-card>
</template>

<style scoped>
.separator {
	height: 3px;
	width: 24px;
	background-color: #EEEEEE;
}

.custom-chip {
	border-width: 2px;
}

.separator {
	position: relative;

	&:before, &:after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		background-color: #EEEEEE;
		border-radius: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&:before {
		left: 0;
	}

	&:after {
		left: 100%;
	}
}
</style>